import { cva } from 'class-variance-authority';

export const cvaPopoverTrigger = cva([
  'header-menu-styles-cvaPopoverTrigger',
  'flex flex-nowrap whitespace-nowrap items-center gap-1.5',
]);

export const cvaPopoverContent = cva([
  'header-menu-styles-cvaPopoverContent',
  'w-80 p-6 bg-general_background_MIII_400_dark',
  'border border-solid border-general_background_MIII_400_dark',
]);

export const cvaProfileInfo = cva([
  'header-menu-styles-cvaProfileInfo',
  'pb-4 border-b border-solid border-general_text_MII_secondary_i',
  'text-general_text_MII_default',
]);

export const cvaProfileEmail = cva([
  'header-menu-styles-cvaProfileEmail',
  'text-font_ds_title_body_body_20',
]);

export const cvaMainContent = cva([
  'header-menu-styles-cvaMainContent',
  'flex flex-col items-start pt-4 gap-2',
]);

export const cvaNoProductMessage = cva([
  'header-menu-styles-cvaNoProductMessage',
  'text-start text-font_ds_title_body_body_18',
  'text-general_text_MII_default',
]);

export const cvaPlanTitle = cva(
  ['header-menu-styles-cvaPlanTitle', 'text-font_ds_title_body_body_20'],
  {
    variants: {
      isHighlight: {
        true: 'text-secondary_warning_SI_400_warning',
        false: 'text-general_MI_500_default',
      },
    },
    defaultVariants: {
      isHighlight: false,
    },
  },
);

export const cvaPlanSubtitle = cva([
  'header-menu-styles-cvaPlanSubtitle',
  'text-general_text_MII_default',
]);

export const cvaProfileRoleTag = cva([
  'header-menu-styles-cvaProfileRoleTag',
  'flex items-center gap-1.5',
  'text-general_text_MII_secondary_ii',
]);
