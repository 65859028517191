import React, { FC } from 'react';
import { cn } from '@app/app/lib/cn';

type SkeletonProps = {
  width?: string;
  height?: string;
  className?: string;
};

const Skeleton: FC<SkeletonProps> = (props) => {
  const { width = '100%', height = '20px', className } = props;
  return (
    <div
      className={cn('rounded-[20px] animate-pulse bg-general_background_MIII_400_dark', className)}
      style={{ width, height }}
    />
  );
};

export default Skeleton;
