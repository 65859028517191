import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { OnboardingMessage } from '../../../components/template';
import { useToastContext } from '@app/hooks/use-toast-provider';
import { useOutletContext } from 'react-router';
import { ONBOARDING_TYPE } from '@app/pages/create/data/onboarding.data';
import {
  useLazyLoadPresentationQuery,
  useSharePresentationMutation,
} from '@app/store/api/presentation.api';
import { analytics } from '@app/app/analytics';

const shareTypes = [
  { type: 'restricted', title: 'Restricted access' },
  { type: 'link', title: 'Anyone with the link' },
  { type: 'pin', title: 'Via PIN' },
];
const ONBOARD_PIN_TYPE = 'pin_share1';
const ONBOARD_TYPE = 'share1';
const SHARING_TITLE_STEPS = [4, 1, 1];
const SHARING_TITLE_STEPS_ITERATED = [6, 0, 5];
const ONBOARD_MESSAGE_SHARE_PIN = {
  title: 'Don’t lose your PIN',
  description:
    'Once you get the link, your PIN will vanish. Make sure you keep a new PIN to unlock the presentation for select people.',
  onBoardType: ONBOARD_PIN_TYPE,
  nowrapDisabled: true,
  isFullWidthChild: true,
  zIndex: 30,
  position: 'horizontal',
  xPosition: 'left',
  yPosition: 'top',
  alignment: 156,
  alignmentX: 493,
  withoutCancel: true,
  // title: 'Save PIN for secure link access',
  // description: 'To complete access setup, click Share via PIN and get link - it\'s the only way to save new settings.',
  // onBoardType: ONBOARD_PIN_TYPE,
  // nowrapDisabled: true,
  // isFullWidthChild: true,
  // zIndex: 30,
  // position: "vertical",
  // xPosition: "center",
  // yPosition: 'top',
  // alignment: 184,
};
const ONBOARD_MESSAGE_SHARE_PIN_LOOSE = {
  title: 'Don’t lose your PIN',
  description:
    'Once you get the link, your PIN will vanish. Make sure you keep a new PIN to unlock the presentation for select people.',
  onBoardType: ONBOARD_PIN_TYPE,
  nowrapDisabled: true,
  isFullWidthChild: true,
  zIndex: 30,
  position: 'horizontal',
  xPosition: 'left',
  yPosition: 'top',
  alignment: 156,
  alignmentX: 493,
  finishText: 'Got it',
  withoutCancel: true,
};
const ONBOARD_MESSAGE_PIN = {
  title: 'Create your PIN',
  description:
    'Enter a PIN using letters, numbers, and special characters to keep your presentation secure.',
  onBoardType: ONBOARD_PIN_TYPE,
  nowrapDisabled: true,
  isFullWidthChild: true,
  zIndex: 30,
  position: 'vertical',
  xPosition: 'edge',
  yPosition: 'bottom',
  alignment: 200,
  withoutCancel: true,
};
const ONBOARD_MESSAGE_RESTRICT = {
  title: "Don't forget to save access settings",
  description: 'Click "Restrict access" to lock your presentation for your eyes only.',
  onBoardType: ONBOARD_TYPE,
  nowrapDisabled: true,
  isFullWidthChild: true,
  zIndex: 30,
  isNoCongratulate: true,
  finishText: 'Got it',
  position: 'horizontal',
  xPosition: 'left',
  yPosition: 'top',
  alignment: 159,
  alignmentX: 493,
  withoutCancel: true,
};
const ONBOARD_MESSAGE_SHARE_LINK = {
  title: 'Your share link is always here',
  description: 'Easily copy your presentation link from the access settings anytime.',
  onBoardType: ONBOARD_TYPE,
  nowrapDisabled: true,
  isFullWidthChild: true,
  zIndex: 30,
  position: 'horizontal',
  xPosition: 'left',
  yPosition: 'top',
  alignment: 168,
  alignmentX: 493,
  withoutCancel: true,
};
const ONBOARD_MESSAGE_GET_LINK = {
  title: 'Get your share link',
  description: 'Press "Generate link" to instantly get a link to share your presentation.',
  onBoardType: ONBOARD_TYPE,
  isFullWidthChild: true,
  zIndex: 30,
  position: 'vertical',
  xPosition: 'center',
  yPosition: 'top',
  alignment: 154,
  withoutCancel: true,
};

const ONBOARD_MESSAGE_ITERATED = [
  {
    title: 'Change access easily',
    description: 'Want to switch access? Choose this option and save your new settings.',
    onBoardType: ONBOARD_PIN_TYPE,
    nowrapDisabled: true,
    position: 'vertical',
    xPosition: 'center',
    zIndex: 30,
    alignment: 230,
    isNoCongratulate: true,
    finishText: 'Finish',
    withoutCancel: true,
  },
  {
    title: 'OOPS',
  },
  {
    title: 'PIN gone missing?',
    description:
      'Click “via PIN”, set your new PIN, and save access. Don’t forget to share it with select users.',
    onBoardType: ONBOARD_PIN_TYPE,
    nowrapDisabled: true,
    position: 'vertical',
    xPosition: 'center',
    finishText: 'Got it',
    zIndex: 30,
    alignment: 190,
    withoutCancel: true,
  },
];
const ONBOARD_MESSAGE = [
  {
    title: 'Manage visibility',
    description:
      'Update access settings whenever you want. Click "restricted access" to keep your presentation private.',
    onBoardType: ONBOARD_TYPE,
    nowrapDisabled: true,
    position: 'vertical',
    xPosition: 'center',
    zIndex: 30,
    alignment: 230,
    withoutCancel: true,
  },
  {
    title: 'Ready to go public?',
    description: 'Hit “Anyone with the link” to begin sharing',
    onBoardType: ONBOARD_TYPE,
    nowrapDisabled: true,
    position: 'vertical',
    xPosition: 'center',
    zIndex: 30,
    alignment: 238,
    withoutCancel: true,
  },
  {
    title: 'Exclusive sharing needed?',
    description:
      'Select "via PIN" to safeguard your presentation with a unique code, granting access to specific people.',
    onBoardType: ONBOARD_PIN_TYPE,
    nowrapDisabled: true,
    position: 'vertical',
    xPosition: 'center',
    zIndex: 30,
    alignment: 189,
    withoutCancel: true,
  },
];

const UNDEFINED_ONBOARDING_STATE = [() => 0, () => {}];

const SharingModal = (props) => {
  const { shared_file: presentationSharedFile, projectId, close, onSave, place, page } = props;
  const [shareMutation] = useSharePresentationMutation();
  const [pinCode, setPinCode] = useState('');
  const { updateSharedFile, context } = useOutletContext();
  const { onboardingMessage } = useOutletContext();
  const [reloadPresentation] = useLazyLoadPresentationQuery();
  const [currentOnboardingKey, changeOnboarding] = onboardingMessage(ONBOARDING_TYPE);
  const [freezeType, setFreezeType] = useState(1);
  const [currentStepOnboarding, changeOnboardingStep] =
    changeOnboarding?.(ONBOARD_TYPE, false) || UNDEFINED_ONBOARDING_STATE;
  const [currentStepPinOnboarding, changePinOnboardingStep] =
    changeOnboarding?.(ONBOARD_PIN_TYPE, false) || UNDEFINED_ONBOARDING_STATE;
  const [showPinCode, setShowPinCode] = useState(false);
  const pinAvailable =
    context.product_balance &&
    context.product_balance.is_active &&
    !context.product_balance.product?.is_trial;
  const [linkCopied, setLinkCopied] = useState(false);
  const [redirectToFiles, setRedirectToFiles] = useState(false);
  const isFinishShareOnboard = localStorage.getItem(`${ONBOARD_TYPE}_process`) === 'finish';
  const isFinishPinShareOnboard = localStorage.getItem(`${ONBOARD_PIN_TYPE}_process`) === 'finish';
  const { getToast } = useToastContext();
  const shared_file =
    presentationSharedFile && Object.keys(presentationSharedFile).length
      ? !presentationSharedFile.short_url && presentationSharedFile.url
        ? { ...presentationSharedFile, short_url: presentationSharedFile.url }
        : { ...presentationSharedFile }
      : undefined;
  let currentType = '';
  if (!shared_file) {
    currentType = 'restricted';
  } else {
    if (shared_file?.is_private) currentType = 'restricted';
    else if (shared_file?.need_pin) currentType = 'pin';
    else currentType = 'link';
  }
  const [currentShareType, setCurrentShareType] = useState(currentType);
  const [activeShareType, setActiveShareType] = useState(currentType);
  const [shareLink, setShareLink] = useState(
    currentType !== 'restricted' ? shared_file.short_url : null,
  );
  const [animateLink, setAnimateLink] = useState(currentType !== 'restricted');

  function onboardChangeWrapping({ step, freeze, type }) {
    if (
      Number(type === ONBOARD_TYPE ? currentStepOnboarding() : currentStepPinOnboarding()) + 1 ===
      Number(step)
    ) {
      type === ONBOARD_TYPE
        ? changeOnboardingStep({ step, freeze })
        : isFinishShareOnboard && changePinOnboardingStep({ step, freeze });
    }
  }

  function handleChangeShareHandle(
    itemType,
    nextStep = 1,
    freezeStep = freezeType,
    onBoardType = ONBOARD_TYPE,
  ) {
    setActiveShareType(itemType);
    setFreezeType(freezeStep);
    onboardChangeWrapping({ step: nextStep, freeze: freezeStep, type: onBoardType });
  }
  function copyLinkAndNextHandle(link, nextStep) {
    copyToClipBoard('This is presentation by Wonderslide. Link: ' + link, true);
    onboardChangeWrapping({ step: nextStep, freeze: freezeType, type: ONBOARD_TYPE });
  }
  function pinCodeInputHandler(value) {
    setPinCode(value);
    if (String(value).length && Number(currentStepPinOnboarding()) === 2)
      onboardChangeWrapping({ step: 3, freeze: 1, type: ONBOARD_PIN_TYPE }); // changePinOnboardingStep({ step: 3, freeze: 1});
  }
  const selectShareType = async (type) => {
    let fd = new FormData(),
      url = '';
    if (type === 'restricted') {
      // fd.append('is_private', 'true');
    }
    if (type === 'link') {
    }
    if (type === 'pin') {
      fd.append('pin', pinCode);
    }

    if (shared_file?.short_url)
      url = `/s/${shared_file.short_url.split('/')[shared_file.short_url.split('/').length - 2]}/`;
    if (type !== 'restricted') url = '/share/' + projectId + '/';
    shareMutation({ correctUrl: url, body: fd, method: type === 'restricted' ? 'DELETE' : 'POST' })
      .unwrap()
      .then((updateShareResponse) => {
        if (!updateShareResponse.status) return;
        let systemMessage = {
          type: 'success',
          autoclose: 5000,
        };
        onSave && onSave();
        let newData = shared_file || { is_owner: true },
          short_url = '';
        if (!shared_file) short_url = updateShareResponse.result.short_url;
        if (shared_file) short_url = shared_file.short_url;
        newData.short_url = short_url;
        if (type !== 'pin' || currentShareType !== 'pin') {
          setAnimateLink(false);
        }
        if (type === 'restricted') {
          systemMessage.msg = 'Access settings are saved';
          systemMessage.msg2 = (
            <>
              Nobody can see your presentation now.
              <br />
              Change access settings if you want to share it.
            </>
          );
          setShareLink(null);
          setPinCode('');
          // newData.is_private = true;
          // newData.need_pin = false;
          newData = undefined;
        }
        if (type === 'link') {
          systemMessage.msg = 'Access settings are saved';
          systemMessage.msg2 = (
            <>
              Now your presentation available by the link.
              <br />
              Don`t forget to share it with your friends!
            </>
          );
          setShareLink(short_url);
          const isCopied = copyToClipBoard(
            'This is presentation by Wonderslide. Link: ' + short_url,
            false,
            'selectShareType',
          );
          if (isCopied) {
            systemMessage.msg3 = (
              <div className="flex items-center gap-1">
                {svgIcons.check} Link copied to the clipboard
              </div>
            );
          }
          setPinCode('');
          newData.is_private = false;
          newData.need_pin = false;
          setTimeout(() => {
            setAnimateLink(true);
          }, 100);
        }
        if (type === 'pin') {
          systemMessage.msg = 'Access settings are saved';
          systemMessage.msg2 = (
            <>
              Your presentation is available via PIN.
              <br />
              Don't forget to share it!
            </>
          );
          setShareLink(short_url);
          const isCopied = copyToClipBoard(
            'This is presentation by Wonderslide. Link: ' + short_url + ' PIN: ' + pinCode,
            'selectShareType',
          );
          if (isCopied) {
            systemMessage.msg3 = (
              <div className="flex items-center gap-1">
                {svgIcons.check} Link and PIN copied to the clipboard
              </div>
            );
          }
          setPinCode('');
          newData.is_private = false;
          newData.need_pin = true;
          setTimeout(() => {
            setAnimateLink(true);
          }, 100);
        }
        setCurrentShareType(type);
        getToast({ systemMessage });

        if (type === 'restricted' && window.location.href === url) {
          setTimeout(() => {
            setRedirectToFiles(true);
          }, 2000);
        } else {
          updateSharedFile && updateSharedFile(newData);
          if (type.match(/link|pin/)) {
            analytics.emitEvent('share', {
              GTM: {
                method: type + '_access',
                file_url: newData.short_url.split('wonderslide.com')[1].replace(/\/$/, ''),
                file_interaction_location: 'file_' + place,
              },
              Amplitude: {
                method: type + ' access',
                'file url': newData.short_url.split('wonderslide.com')[1].replace(/\/$/, ''),
                'file interaction location': 'file ' + place,
              },
            });
          }
        }
        reloadPresentation({ projectId });
      });
    // .finally(() => {
    // onboardChangeWrapping({ step: 4, freeze: freezeType, type: ONBOARD_PIN_TYPE }) });
    // let response = await fetch(url, {
    //     method: type === 'restricted' ? 'delete' : 'post',
    //     body: fd,
    //     headers: xhrHeaders()
    // }).finally(() => { onboardChangeWrapping({ step: 4, freeze: freezeType, type: ONBOARD_PIN_TYPE }) });
    // response = await response.json();
    // if (!response.status) return;
    //
    // let systemMessage = {
    //     type: 'success',
    //     autoclose: 5000
    // };
    //
    // onSave && onSave();
    //
    // let newData = shared_file || {is_owner: true},
    //     short_url = '';
    // if (!shared_file) short_url = response.result.short_url;
    // if (shared_file) short_url = shared_file.short_url;
    // newData.short_url = short_url;
    //
    // if (type !== 'pin' || currentShareType !== 'pin') {
    //     setAnimateLink(false);
    // }
    //
    // if (type === 'restricted') {
    //     systemMessage.msg = 'Access settings are saved';
    //     systemMessage.msg2 = <>Nobody can see your presentation now.<br/>Change access settings if you want to share it.</>;
    //     setShareLink(null);
    //     setPinCode('');
    //     // newData.is_private = true;
    //     // newData.need_pin = false;
    //     newData = undefined;
    // }
    // if (type === 'link') {
    //     systemMessage.msg = 'Access settings are saved';
    //     systemMessage.msg2 = <>Now your presentation available by the link.<br/>Don`t forget to share it with your friends!</>;
    //     systemMessage.msg3 = <>{svgIcons.check} Link copied to the clipboard</>;
    //     setShareLink(short_url);
    //     copyToClipBoard('This is presentation by Wonderslide. Link: ' + short_url);
    //     setPinCode('');
    //     newData.is_private = false;
    //     newData.need_pin = false;
    //     setTimeout(() => {
    //         setAnimateLink(true);
    //     }, 100);
    // }
    // if (type === 'pin') {
    //     systemMessage.msg = 'Access settings are saved';
    //     systemMessage.msg2 = <>Your presentation is available via PIN.<br/>Don't forget to share it!</>;
    //     systemMessage.msg3 = <>{svgIcons.check} Link and PIN copied to the clipboard</>;
    //     setShareLink(short_url);
    //     copyToClipBoard('This is presentation by Wonderslide. Link: ' + short_url + ' PIN: ' + pinCode);
    //     setPinCode('');
    //     newData.is_private = false;
    //     newData.need_pin = true;
    //     setTimeout(() => {
    //         setAnimateLink(true);
    //     }, 100);
    // }
    // setCurrentShareType(type);
    // getToast({systemMessage});
    //
    // if (type === 'restricted' && window.location.href === url) {
    //     setTimeout(() => {
    //         setRedirectToFiles(true);
    //     }, 2000);
    // }
    // else {
    //     updateSharedFile && updateSharedFile(newData);
    // }
  };

  const copyToClipBoard = (text, isManual, place) => {
    if (!document.hasFocus()) {
      console.error('Document is not focused', place);
      return false;
    }
    window.navigator.clipboard
      .writeText(text)
      .then(() => {
        if (isManual) {
          setLinkCopied(true);
          setTimeout(() => setLinkCopied(false), 3000);
        }
      })
      .catch((err) => console.error('Failed to copy text: ', err, place));
    return true;
  };

  const onClose = () => {
    if (activeShareType !== currentShareType || (currentShareType === 'pin' && pinCode)) {
      getToast({
        systemMessage: {
          type: 'warning',
          autoclose: 5000,
          msg: 'Access settings are not saved',
          msg2: (
            <>
              To save new access settings, click the chosen access type button before closing the
              window.
              <br />
              Closing without saving will discard your changes.
            </>
          ),
        },
      });
    }
    close();
  };
  const renderPinInput = () => (
    <div
      className={
        'share_btn gen_btn_main' +
        (pinCode.length < 1 || pinCode.length > 50 ? ' share_btn-disabled' : '')
      }
      onClick={() => {
        selectShareType('pin').finally(() => {
          onboardChangeWrapping({ step: 5, freeze: 5, type: ONBOARD_PIN_TYPE });
        });
      }}
    >
      {currentShareType !== 'pin' && <>Share PIN and get link</>}
      {currentShareType === 'pin' && !pinCode && <>{svgIcons.check}Shared via PIN</>}
      {currentShareType === 'pin' && pinCode && <>{svgIcons.unlocked}Confirm and share</>}
    </div>
  );
  const onboardingRadioActions = (itemType, i, iterStep, boardType) => {
    if (iterStep === 6 && boardType === ONBOARD_TYPE) {
      return {
        onFinish: () => {
          changeOnboardingStep({ step: 1, freeze: 1 });
          changePinOnboardingStep({ step: 1, freeze: 1 });
          localStorage.setItem(`${ONBOARD_TYPE}_process`, 'finish');
        },
      };
    } else if (iterStep === 6 && boardType === ONBOARD_PIN_TYPE) {
      return {
        onFinish: () => {
          changePinOnboardingStep({ step: 1, freeze: 1 });
          localStorage.setItem(`${ONBOARD_PIN_TYPE}_process`, 'finish');
        },
      };
    } else if (iterStep === 5 && boardType === ONBOARD_PIN_TYPE) {
      return {
        onNext: () => {
          onboardChangeWrapping({ step: 6, freeze: 1, type: ONBOARD_PIN_TYPE });
        },
      };
    }
    // }else{
    // 	return {onNext: () => handleChangeShareHandle(itemType, Number(iterStep) + 1, iterStep[i], boardType)}
    // }
  };

  if (redirectToFiles) return <Navigate to="/projects/" />;
  return (
    <div className="modal z-[999]">
      <div className="modal_overlay" onClick={onClose}></div>
      <div className="share">
        <div className="share_inner">
          <div className="modal_close" onClick={onClose}>
            {svgIcons.close}
          </div>
          <h2 className="share_title">
            {svgIcons.share1}
            Share all slides?
          </h2>
          <div className="share_types">
            {shareTypes.map((item, i) => (
              <OnboardingMessage
                key={i}
                boardId={SHARING_TITLE_STEPS[i]}
                isActivate={
                  isFinishShareOnboard ? currentStepPinOnboarding() : currentStepOnboarding()
                }
                forceHidden={activeShareType === item.type}
                nowrapDisabled={true}
                onBoardTypeActive={isFinishShareOnboard ? ONBOARD_PIN_TYPE : ONBOARD_TYPE}
                {...ONBOARD_MESSAGE[i]}
                {...(item.type, i, SHARING_TITLE_STEPS[i], ONBOARD_MESSAGE[i].onBoardType)}
              >
                <OnboardingMessage
                  key={i}
                  boardId={SHARING_TITLE_STEPS_ITERATED[i]}
                  isActivate={
                    isFinishShareOnboard ? currentStepPinOnboarding() : currentStepOnboarding()
                  }
                  forceHidden={
                    activeShareType === item.type || (isFinishShareOnboard && pinAvailable)
                  }
                  onBoardTypeActive={isFinishShareOnboard ? ONBOARD_PIN_TYPE : ONBOARD_TYPE}
                  {...ONBOARD_MESSAGE_ITERATED[i]}
                  nowrapDisabled={true}
                  {...onboardingRadioActions(
                    item.type,
                    i,
                    SHARING_TITLE_STEPS_ITERATED[i],
                    ONBOARD_MESSAGE_ITERATED[i].onBoardType,
                  )}
                >
                  <div
                    className={'share_type' + (activeShareType === item.type ? ' active' : '')}
                    onClick={() =>
                      handleChangeShareHandle(
                        item.type,
                        SHARING_TITLE_STEPS[i] + 1,
                        SHARING_TITLE_STEPS[i],
                        isFinishShareOnboard
                          ? ONBOARD_MESSAGE_ITERATED[i].onBoardType
                          : ONBOARD_MESSAGE[i].onBoardType,
                      )
                    }
                    key={i}
                  >
                    {item.title}
                  </div>
                </OnboardingMessage>
              </OnboardingMessage>
            ))}
          </div>
          <div className="share_access_info">
            {activeShareType === 'restricted' && 'Presentation only available to you'}
            {activeShareType === 'link' && 'Anyone with this link can view your presentation'}
            {activeShareType === 'pin' && !pinAvailable && (
              <strong>Upgrade your plan to unlock this feature</strong>
            )}
            {activeShareType === 'pin' && pinAvailable && 'Set your PIN'}
          </div>

          {activeShareType === 'restricted' && (
            <OnboardingMessage
              boardId={5}
              isActivate={currentStepOnboarding()}
              nowrapDisabled={true}
              onFinish={() =>
                selectShareType('restricted').finally(() => {
                  if (Number(currentStepOnboarding()) === 5) {
                    localStorage.setItem(`${ONBOARD_TYPE}_process`, 'finish');
                    changePinOnboardingStep({ step: 1, freeze: 1 });
                  }
                })
              }
              {...ONBOARD_MESSAGE_RESTRICT}
            >
              <div
                className={
                  'share_btn gen_btn_main' +
                  (currentShareType === activeShareType ? ' share_btn-disabled' : '')
                }
                onClick={() => {
                  selectShareType('restricted').finally(() => {
                    if (Number(currentStepOnboarding()) === 5) {
                      localStorage.setItem(`${ONBOARD_TYPE}_process`, 'finish');
                      changePinOnboardingStep({ step: 1, freeze: 1 });
                    }
                  });
                }}
              >
                {currentShareType === 'restricted' ? (
                  <> {svgIcons.locked} Access is restricted </>
                ) : (
                  <> {svgIcons.unlocked} Restrict access </>
                )}
              </div>
            </OnboardingMessage>
          )}

          {activeShareType === 'link' && (
            <OnboardingMessage
              boardId={2}
              isActivate={currentStepOnboarding()}
              {...ONBOARD_MESSAGE_GET_LINK}
            >
              <div
                className={
                  'share_btn gen_btn_main' +
                  (currentShareType === activeShareType ? ' share_btn-disabled' : '')
                }
                onClick={() => {
                  selectShareType('link').finally(() => {
                    onboardChangeWrapping({ step: 3, freeze: freezeType, type: ONBOARD_TYPE });
                  });
                }}
              >
                {currentShareType === 'link' ? <>{svgIcons.check}Access shared</> : 'Get the link'}
              </div>
            </OnboardingMessage>
          )}

          {activeShareType === 'pin' && !pinAvailable && (
            <Link to="/pricing" className="share_btn gen_btn_main">
              View plans
            </Link>
          )}

          {activeShareType === 'pin' && pinAvailable && (
            <>
              <OnboardingMessage
                boardId={2}
                nowrapDisabled={true}
                isActivate={currentStepPinOnboarding()}
                {...ONBOARD_MESSAGE_PIN}
              >
                <div className={'share_pin' + (pinCode.length > 50 ? ' error' : '')}>
                  <input
                    className="share_pin_input"
                    type={showPinCode ? 'text' : 'password'}
                    value={pinCode}
                    onChange={(e) => {
                      pinCodeInputHandler(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.code === 'Enter' && selectShareType('pin');
                    }}
                    autoFocus
                  />
                  <div
                    className="share_pin_show"
                    onClick={() => {
                      setShowPinCode(!showPinCode);
                      onboardChangeWrapping({
                        step: 3,
                        freeze: freezeType,
                        type: ONBOARD_PIN_TYPE,
                      });
                    }}
                  >
                    {showPinCode ? svgIcons.eye : svgIcons.eye_closed}
                  </div>
                </div>
              </OnboardingMessage>
              {(!shared_file || !shared_file.need_pin || !pinCode) && pinCode.length < 51 && (
                <div className="share_tip">
                  Enter a character set that you can remember, up to 50 characters
                </div>
              )}
              {shared_file && shared_file.need_pin && pinCode && pinCode.length < 51 && (
                <div className="share_tip warning">
                  After changing the PIN, the old one becomes invalid
                </div>
              )}
              {pinCode.length > 50 && (
                <div className="share_tip error">Your PIN is too long. Limit is 50 characters</div>
              )}

              {currentShareType === 'pin' && (
                <div className={'share_link_wrap' + (animateLink ? ' go' : '')}>
                  <div className="share_access_info">
                    Anyone with this link can view your presentation
                  </div>
                  <div
                    className="share_link"
                    onClick={() =>
                      onboardChangeWrapping({ step: 4, freeze: freezeType, type: ONBOARD_TYPE })
                    }
                  >
                    <div className="share_link_link">{shareLink}</div>
                    <div
                      className="share_link_copy"
                      onClick={() => {
                        copyToClipBoard(
                          'This is presentation by Wonderslide. Link: ' + shareLink,
                          true,
                        );
                      }}
                    >
                      {svgIcons.copyToClipboard}
                    </div>
                    {linkCopied && <div className="share_link_copied">Link copied</div>}
                  </div>
                </div>
              )}
              {
                <OnboardingMessage
                  boardId={4}
                  isActivate={currentStepPinOnboarding()}
                  nowrapDisabled={true}
                  onNext={() => changePinOnboardingStep({ step: 5, freeze: 1 })}
                  {...ONBOARD_MESSAGE_SHARE_PIN}
                >
                  <OnboardingMessage
                    boardId={3}
                    isActivate={currentStepPinOnboarding()}
                    nowrapDisabled={true}
                    onNext={() => {
                      changePinOnboardingStep({ step: 5, freeze: 1 });
                    }}
                    {...ONBOARD_MESSAGE_SHARE_PIN_LOOSE}
                  >
                    {renderPinInput()}
                  </OnboardingMessage>
                </OnboardingMessage>
              }
            </>
          )}

          {(activeShareType !== 'pin' || pinAvailable) && (
            <div className="share_tip">
              {currentShareType === activeShareType
                ? 'To change access settings choose other access option above'
                : 'To save access settings push the button above'}
            </div>
          )}

          {currentShareType === 'link' && activeShareType === 'link' && (
            <OnboardingMessage
              boardId={3}
              isActivate={currentStepOnboarding()}
              onNext={() => copyLinkAndNextHandle(shareLink, 4)}
              {...ONBOARD_MESSAGE_SHARE_LINK}
            >
              <div className={'share_link_wrap' + (animateLink ? ' go' : '')}>
                <div className="share_link">
                  <div className="share_link_link">{shareLink}</div>
                  <div
                    className="share_link_copy"
                    onClick={() => {
                      copyToClipBoard(
                        'This is presentation by Wonderslide. Link: ' + shareLink,
                        true,
                      );
                    }}
                  >
                    {svgIcons.copyToClipboard}
                  </div>
                  {linkCopied && <div className="share_link_copied">Link copied</div>}
                </div>
              </div>
            </OnboardingMessage>
          )}
        </div>
      </div>
    </div>
  );
};

export default SharingModal;

const svgIcons = {
  close: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor">
      <path d="M10.192 0.343994L5.94897 4.58599L1.70697 0.343994L0.292969 1.75799L4.53497 5.99999L0.292969 10.242L1.70697 11.656L5.94897 7.41399L10.192 11.656L11.606 10.242L7.36397 5.99999L11.606 1.75799L10.192 0.343994Z" />
    </svg>
  ),
  share1: (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none">
      <path
        d="M13.2261 18.0928C11.731 18.0838 10.2975 17.4897 9.2264 16.4352C7.09516 14.3791 5.1239 10.723 5.52387 7.19408C5.62174 5.973 6.05294 4.80333 6.76947 3.81529C7.51219 2.78884 8.48393 1.95395 9.60579 1.37841C10.7276 0.802868 11.968 0.502918 13.2261 0.502918C14.4841 0.502918 15.7245 0.802868 16.8463 1.37841C17.9682 1.95395 18.9399 2.78884 19.6826 3.81529C20.4045 4.80356 20.8398 5.97555 20.9397 7.19986V7.22873C21.2711 10.8039 19.317 14.431 17.2314 16.4699C16.1528 17.512 14.7181 18.0934 13.2261 18.0928ZM13.2261 3.38789C12.4127 3.38841 11.611 3.58395 10.8869 3.95845C10.1629 4.33296 9.53698 4.87574 9.0607 5.54223C8.64465 6.11759 8.39894 6.80063 8.35219 7.51174C8.08365 9.93176 9.57494 12.7792 11.2091 14.3444C11.475 14.6177 11.792 14.8348 12.1417 14.9829C12.4915 15.1311 12.8669 15.2074 13.2461 15.2074C13.6252 15.2074 14.0007 15.1311 14.3504 14.9829C14.7001 14.8348 15.0171 14.6177 15.283 14.3444C17.1571 12.5366 18.3228 9.72384 18.1399 7.50019C18.096 6.7912 17.8521 6.10976 17.4371 5.53645C16.9495 4.8671 16.3123 4.32363 15.5776 3.95027C14.8428 3.57691 14.0313 3.38422 13.2089 3.38789H13.2261Z"
        fill="#6E718F"
      />
      <path
        d="M20.6597 36.4711H5.80384C5.10854 36.4726 4.41977 36.3356 3.77689 36.0679C3.13401 35.8001 2.54964 35.4069 2.0572 34.9108C1.56475 34.4146 1.17388 33.8252 0.906932 33.1762C0.639985 32.5272 0.502199 31.8314 0.501449 31.1286V29.9735C0.474556 28.4502 0.822272 26.9441 1.51339 25.5902C2.2045 24.2364 3.21738 23.0772 4.4611 22.2167C5.95752 21.2223 7.70989 20.6942 9.50067 20.6977H16.9286C18.1325 20.6838 19.3267 20.9164 20.4392 21.3816C21.5517 21.8467 22.5596 22.5347 23.4021 23.4041C24.2445 24.2735 24.9042 25.3063 25.3412 26.4403C25.7782 27.5743 25.9836 28.786 25.9449 30.0023V31.1575C25.9359 32.5689 25.3753 33.9195 24.3852 34.9149C23.3952 35.9102 22.056 36.4696 20.6597 36.4711ZM9.50067 23.5855C8.26624 23.5816 7.05799 23.9452 6.02668 24.6309C5.18152 25.2377 4.49637 26.0445 4.03119 26.981C3.56601 27.9174 3.33499 28.9548 3.35834 30.0023V31.1575C3.35985 31.808 3.61618 32.4315 4.07126 32.8915C4.52634 33.3515 5.14313 33.6106 5.7867 33.6121H20.6426C21.2866 33.6121 21.9043 33.3535 22.3597 32.8932C22.8151 32.4329 23.0709 31.8085 23.0709 31.1575V30.0023C23.0943 28.9548 22.8632 27.9174 22.3981 26.981C21.9329 26.0445 21.2477 25.2377 20.4026 24.6309C19.3713 23.9452 18.163 23.5816 16.9286 23.5855H9.50067Z"
        fill="#6E718F"
      />
      <path
        d="M32.5158 22.2167C31.0194 21.2223 29.267 20.6942 27.4762 20.6977H23.8137C24.8532 21.4907 25.7401 22.4694 26.4306 23.5855H27.4762C28.3037 23.5736 29.1248 23.733 29.889 24.0539C30.6532 24.3748 31.3443 24.8505 31.9197 25.4517C32.4952 26.0528 32.9427 26.7667 33.2349 27.5493C33.5271 28.332 33.6576 29.1668 33.6186 30.0023V31.1575C33.6171 31.808 33.3607 32.4315 32.9056 32.8915C32.4506 33.3515 31.8338 33.6106 31.1902 33.6121H27.8248C27.4627 34.696 26.8631 35.6832 26.0707 36.5H31.2131C32.6143 36.4985 33.9578 35.9351 34.9486 34.9335C35.9395 33.9319 36.4968 32.5739 36.4983 31.1575V30.0023C36.5275 28.4722 36.1785 26.9588 35.4831 25.5993C34.7877 24.2399 33.7678 23.0772 32.5158 22.2167Z"
        fill="#6E718F"
      />
      <path
        d="M31.4759 7.22296V7.19408C31.376 5.96977 30.9407 4.79779 30.2189 3.80952C29.4687 2.78001 28.489 1.9438 27.3592 1.36878C26.2295 0.793757 24.9815 0.496123 23.7166 0.500038C22.5964 0.505793 21.4902 0.751872 20.4711 1.222C20.8457 1.59311 21.1915 1.99282 21.5053 2.41757C21.788 2.79793 22.0384 3.20174 22.2538 3.62469C22.7286 3.47074 23.2237 3.39089 23.7223 3.38789C24.5383 3.39168 25.3421 3.5885 26.0693 3.96259C26.7966 4.33668 27.4271 4.87766 27.9105 5.54223C28.3326 6.11522 28.5825 6.79897 28.6304 7.51174C28.8304 9.73539 27.6705 12.5482 25.7735 14.356C25.5077 14.6293 25.1906 14.8463 24.8409 14.9945C24.4912 15.1426 24.1158 15.2189 23.7366 15.2189C23.3574 15.2189 22.982 15.1426 22.6322 14.9945C22.2825 14.8463 21.9655 14.6293 21.6996 14.356L21.6253 14.2809C21.1715 15.1417 20.6417 15.9593 20.0426 16.724C21.1298 17.657 22.5228 18.1452 23.948 18.0928C25.3732 18.0405 26.7275 17.4513 27.7448 16.441C29.8532 14.4426 31.8073 10.7981 31.4759 7.22296Z"
        fill="#6E718F"
      />
    </svg>
  ),
  locked: (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="currentColor">
      <path d="M8.5 0.5C5.743 0.5 3.5 2.743 3.5 5.5V7.5H2.5C1.397 7.5 0.5 8.397 0.5 9.5V18.5C0.5 19.603 1.397 20.5 2.5 20.5H14.5C15.603 20.5 16.5 19.603 16.5 18.5V9.5C16.5 8.397 15.603 7.5 14.5 7.5H13.5V5.5C13.5 2.743 11.257 0.5 8.5 0.5ZM5.5 5.5C5.5 3.846 6.846 2.5 8.5 2.5C10.154 2.5 11.5 3.846 11.5 5.5V7.5H5.5V5.5ZM14.502 18.5H9.5V16.222C10.095 15.875 10.5 15.237 10.5 14.5C10.5 13.397 9.603 12.5 8.5 12.5C7.397 12.5 6.5 13.397 6.5 14.5C6.5 15.236 6.905 15.875 7.5 16.222V18.5H2.5V9.5H14.5L14.502 18.5Z" />
    </svg>
  ),
  unlocked: (
    <svg width="16" height="21" viewBox="0 0 16 21" fill="currentColor">
      <path d="M13 6.5V5.5C13 2.743 10.757 0.5 8 0.5C5.243 0.5 3 2.743 3 5.5V8.5H2C0.897 8.5 0 9.397 0 10.5V18.5C0 19.603 0.897 20.5 2 20.5H14C15.103 20.5 16 19.603 16 18.5V10.5C16 9.397 15.103 8.5 14 8.5H5V5.5C5 3.846 6.346 2.5 8 2.5C9.654 2.5 11 3.846 11 5.5V6.5H13ZM14 10.5L14.002 18.5H2V10.5H14Z" />
    </svg>
  ),
  check: (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="currentColor">
      <path d="M4.99997 8.58597L1.70697 5.29297L0.292969 6.70697L4.99997 11.414L14.707 1.70697L13.293 0.292969L4.99997 8.58597Z" />
    </svg>
  ),
  copyToClipboard: (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor">
      <path d="M20 2.5H10C8.897 2.5 8 3.397 8 4.5V8.5H4C2.897 8.5 2 9.397 2 10.5V20.5C2 21.603 2.897 22.5 4 22.5H14C15.103 22.5 16 21.603 16 20.5V16.5H20C21.103 16.5 22 15.603 22 14.5V4.5C22 3.397 21.103 2.5 20 2.5ZM4 20.5V10.5H14L14.002 20.5H4ZM20 14.5H16V10.5C16 9.397 15.103 8.5 14 8.5H10V4.5H20V14.5Z" />
    </svg>
  ),
  eye: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 9C11.206 9.00524 10.4459 9.32299 9.88447 9.88447C9.32299 10.4459 9.00524 11.206 9 12C9 13.642 10.358 15 12 15C13.641 15 15 13.642 15 12C15 10.359 13.641 9 12 9Z" />
      <path d="M12.0003 5C4.36729 5 2.07329 11.617 2.05229 11.684L1.94629 12L2.05129 12.316C2.07329 12.383 4.36729 19 12.0003 19C19.6333 19 21.9273 12.383 21.9483 12.316L22.0543 12L21.9493 11.684C21.9273 11.617 19.6333 5 12.0003 5ZM12.0003 17C6.64929 17 4.57629 13.154 4.07429 12C4.57829 10.842 6.65229 7 12.0003 7C17.3513 7 19.4243 10.846 19.9263 12C19.4223 13.158 17.3483 17 12.0003 17Z" />
    </svg>
  ),
  eye_closed: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
      <path d="M9.99973 17C10.9457 17 11.8097 16.897 12.5977 16.719L10.8407 14.962C10.5677 14.983 10.2907 15 9.99973 15C4.64874 15 2.57573 11.154 2.07373 9.99997C2.45068 9.15878 2.95978 8.38331 3.58173 7.70297L2.18373 6.30497C0.645735 7.97197 0.0627348 9.65097 0.0517348 9.68397C-0.0172449 9.88933 -0.0172449 10.1116 0.0517348 10.317C0.0727348 10.383 2.36673 17 9.99973 17ZM9.99973 2.99997C8.16273 2.99997 6.65373 3.39597 5.39573 3.98097L1.70673 0.292969L0.292735 1.70697L18.2927 19.707L19.7067 18.293L16.3877 14.974C19.0017 13.023 19.9347 10.359 19.9487 10.317C20.0177 10.1116 20.0177 9.88933 19.9487 9.68397C19.9267 9.61697 17.6327 2.99997 9.99973 2.99997ZM14.9717 13.558L12.6917 11.278C12.8817 10.888 12.9997 10.459 12.9997 9.99997C12.9997 8.35897 11.6407 6.99997 9.99973 6.99997C9.54074 6.99997 9.11173 7.11797 8.72273 7.30897L6.91473 5.50097C7.90725 5.16038 8.95044 4.99097 9.99973 4.99997C15.3507 4.99997 17.4237 8.84597 17.9257 9.99997C17.6237 10.692 16.7597 12.342 14.9717 13.558Z" />
    </svg>
  ),
};
