import React, { FC } from 'react';

import { cn } from '@app/app/lib/cn';

import * as projectIcons from './project-icons';
import { IconNames } from './icon-names.type';

export type IconProps = {
  name: IconNames;
  size?: number | string;
  className?: string;
  onClick?: () => void;
};
const Icon: FC<IconProps> = (props) => {
  const { className, name, onClick, size, ...remainingProps } = props;
  const IconElem = Object({ ...projectIcons })[name];
  const renderIcon = () => <IconElem size={size} {...remainingProps} />;
  if (!name) return <></>;
  return (
    <div
      onClick={() => onClick?.()}
      className={cn('flex justify-center items-center', className)}
      style={{
        width: `${size}px`,
        maxHeight: `${size}px`,
        display: 'inline-flex',
        alignItems: 'center',
      }}
      {...remainingProps}
    >
      {renderIcon()}
    </div>
  );
};
export default Icon;
