import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import app from './react/app';
import './styles.js';

// window.onunload = function () {}; TODO: Best Practice Light House

if (!location.href.match(/127.0.0.1|localhost/)) {
  Sentry.init({
    dsn: 'https://8de4f12a0129ebe3ee3dadca91d44b17@sentry.wonderslide.com/1',
    environment: location.host === 'dev.wonderslide.com' ? 'develop' : 'production',
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['wonderslide-com-key'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
  });
  console.debug(Sentry);
}

if (document.getElementById('root')) {
  app();
}

if (document.getElementById('root-slide_shooting')) {
  import('./react/slideShooting/slideShooting').then(({ default: SlideShooting }) => {
    ReactDOM.render(<SlideShooting />, document.getElementById('root-slide_shooting'));
  });
}

if (document.getElementById('auth_form')) {
  import('./modules/auth').then(({ default: auth }) => {
    auth();
  });
}

if (document.getElementById('root-corporate_users')) {
  import('./react/cabinet/corporate_users').then(({ default: CorporateUsers }) => {
    ReactDOM.render(
      <CorporateUsers corporateData={window.corporateData} />,
      document.getElementById('root-corporate_users'),
    );
  });
}

if (document.getElementById('root-corporate_attribute')) {
  import('./react/cabinet/corporate_attribute').then(({ default: CorporateAttribute }) => {
    ReactDOM.render(
      <CorporateAttribute preset={window?.context?.preset} />,
      document.getElementById('root-corporate_attribute'),
    );
  });
}
