import { Outlet, useOutletContext } from 'react-router';

import { FC, useRef, useState } from 'react';

import { Header, StageHeader } from '@app/widgets/header';
import { CommonOutletContext } from '@app/pages/common.outlet';
import { motion } from 'framer-motion';

export const StagedLayout: FC = () => {
  const context = useOutletContext<CommonOutletContext>();

  const [isOpen, toggleOpen] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <motion.div animate={isOpen ? 'open' : 'closed'}>
      <motion.div
        initial={{ y: `-100%` }}
        variants={{
          open: { y: '0' },
          closed: { y: `-100%` },
        }}
        transition={{ type: 'tween', stiffness: 50, damping: 10 }}
        ref={headerRef}
        className="absolute top-0 left-0 right-0 z-[101]"
      >
        <Header
          isTransparent={false}
          isOpen={isOpen}
          isWithStaged
          onToggle={() => toggleOpen((prev) => !prev)}
        />
      </motion.div>

      <motion.div
        initial={{ y: `0` }}
        variants={{
          open: { y: `${headerRef.current?.scrollHeight}px` },
          closed: { y: '0' },
        }}
        transition={{ type: 'tween', stiffness: 50, damping: 10 }}
        className="relative z-[100]"
      >
        <StageHeader isOpen={isOpen} onToggle={() => toggleOpen((prev) => !prev)} />
      </motion.div>

      <motion.main
        initial={{ paddingTop: `0` }}
        variants={{
          open: { paddingTop: `${headerRef.current?.scrollHeight}px` },
          closed: { y: '0' },
        }}
        transition={{ type: 'tween', stiffness: 50, damping: 10 }}
        className="min-h-[calc(100vh-100px)]"
      >
        <Outlet context={context} />
      </motion.main>
    </motion.div>
  );
};
