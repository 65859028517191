import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const headerSlice = createSlice({
  name: 'header-menu',
  initialState: false,
  reducers: {
    toggleHeaderMenu: (state, action: PayloadAction<boolean>) => action.payload ?? state,
  },
});

const { actions, reducer } = headerSlice;

export const isHeaderMenuOpen = (state: { headerSlice: boolean }) => state.headerSlice;
export const { toggleHeaderMenu } = actions;
export default reducer;

// TODO: Add public api and change imports
