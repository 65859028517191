import { FC, PropsWithChildren } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@app/app/lib/cn';

const cvaRoot = cva(['mx-auto '], {
  variants: {
    variant: {
      full: 'max-w-full',
      default: 'max-w-[1320px]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type ContainerProps = {
  className?: string;
} & VariantProps<typeof cvaRoot>;

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
  className,
  variant,
  children,
}) => {
  return <div className={cn(cvaRoot({ variant }), className)}>{children}</div>;
};
