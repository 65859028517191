import { FC, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { route_path } from '@app/utils/route_path';

import { WithoutHeaderLayout } from '@app/components/layout';

import { CommonOutlet, LoginPage, MainPage, RedirectPaymentPage } from '../pages';

import { analytics } from './analytics';

import { DefaultLayout, StagedLayout } from './layouts';

const LegalPage = lazy(() => import('../pages/legal/legal.page'));
const FinalizeStagePage = lazy(() => import('../pages/finalize-stage'));
const TermsPage = lazy(() => import('../pages/legal/terms.page'));
const PrivacyPage = lazy(() => import('../pages/legal/privacy.page'));
const CookiePage = lazy(() => import('../pages/legal/cookie.page'));
const FeaturesPage = lazy(() => import('../pages/features/features.page'));
const AboutPage = lazy(() => import('../pages/about/about.page'));
const Wondercheck = lazy(() => import('../wondercheck/wondercheck.js'));
const SharedFile = lazy(() => import('../sharing/SharedFile.js'));
const PricingPage = lazy(() => import('../pages/pricing/pricing.page'));
const PasswordReset = lazy(() => import('../auth/passwordReset.js'));
const PasswordResetConfirm = lazy(() => import('../auth/passwordResetConfirm.js'));
const RegistrationPage = lazy(() => import('../pages/registration/registration.page.js'));
const Error404Page = lazy(() => import('../pages/error/error404.page'));
const ProjectIdPage = lazy(() => import('../pages/project/project-id.page'));
const ProjectsPage = lazy(() => import('../pages/projects/projects.page'));
const CreateStartPage = lazy(() => import('../pages/create/create-start.page'));
const SetupStylePage = lazy(() => import('../pages/create/setup-style.page'));
const UploadDraftPage = lazy(() => import('../pages/create/upload-draft.page'));

type Props = {
  context: unknown;
  updateSharedFile: () => void;
  logout: () => void;
};

export const Router: FC<Props> = ({ context, updateSharedFile, logout }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.pathname.match(/\/create\//)) window.sessionStorage.removeItem('genState');
    if (!location.pathname.match(/\/create-draft\//))
      window.sessionStorage.removeItem('draftState');

    if (location.pathname.match(/upload-draft/) && !location.search.includes('demo')) {
      analytics.emitEvent('file_upload_open', {
        GTM: {},
        Amplitude: {},
      });
    }
  }, [location.pathname, location.hash]);

  return (
    <Routes>
      <Route
        path={route_path.main}
        element={<CommonOutlet updateSharedFile={updateSharedFile} logout={logout} />}
      >
        {/* TODO: Refactor */}
        <Route index element={<MainPage />} />
        <Route path={route_path.login} element={<LoginPage />} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error*/}
        <Route path="/register/" element={<RegistrationPage context={context} />} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error*/}
        <Route path="/password-reset/" element={<PasswordReset context={context} />} />
        <Route path="/password-reset/:token" element={<PasswordResetConfirm context={context} />} />

        {/* TODO: Refactor */}
        <Route path={route_path.main} element={<WithoutHeaderLayout />}>
          <Route path={route_path.about} element={<AboutPage />} />
          <Route path={route_path.features} element={<FeaturesPage />} />
        </Route>

        {/***** Default pages *****/}
        <Route path={route_path.main} element={<DefaultLayout />}>
          <Route path={route_path.pricing} element={<PricingPage />} />
          <Route path={route_path.create} element={<CreateStartPage />} />
          <Route path={route_path.projects} element={<ProjectsPage />} />
          <Route
            path="/s/*"
            element={<SharedFile context={context} updateSharedFile={updateSharedFile} />}
          />
          <Route path={`${route_path.redirectPayment}/*`} element={<RedirectPaymentPage />} />
        </Route>

        {/***** Staged *****/}
        <Route path={route_path.main} element={<StagedLayout />}>
          {/***** Upload draft page *****/}
          <Route
            path={`${route_path.uploadDraft}`}
            element={<UploadDraftPage isDemo={location.search.includes('demo')} />}
          />
          {/***** Setup styles page *****/}
          <Route path={`${route_path.setupStyle}:projectId/`} element={<SetupStylePage />} />
          {/***** Project page *****/}
          <Route path={`${route_path.project}:projectId/`} element={<ProjectIdPage />} />
          {/* TODO: Prototype page */}
          <Route path={route_path.finalize} element={<FinalizeStagePage />} />
        </Route>

        {/***** Wondercheck *****/}
        <Route path="/wondercheck/*" element={<Wondercheck />} />

        {/***** Legal *****/}
        <Route path={route_path.legal} element={<DefaultLayout />}>
          <Route index element={<LegalPage />} />
          <Route path={route_path.legal_terms} element={<TermsPage />} />
          <Route path={route_path.legal_cookie} element={<CookiePage />} />
          <Route path={route_path.legal_privacy} element={<PrivacyPage />} />
        </Route>
      </Route>

      {/***** NotFound *****/}
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
};
