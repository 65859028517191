/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { cn } from '@app/app/lib';

type InputFieldProps<T extends FieldValues> = {
  label?: string;
  name?: Path<T>;
  placeholder?: string;
  type?: string;
  // onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegister<T>;
  rules?: Record<string, any>;
  extraClasses?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputField = forwardRef<HTMLInputElement, InputFieldProps<any>>(
  (
    {
      label,
      name,
      placeholder,
      type = 'text',
      register,
      rules,
      extraClasses,
      ...inputProps
    }: InputFieldProps<any>,
    ref,
  ) => {
    const inputPropsFromRegister = register && name ? register(name, rules) : {};
    return (
      <div className="flex flex-col relative h-11 w-full">
        <label
          htmlFor={name}
          className="text-sm text-general_text_MII_disabled text-[12px] leading-[10px] absolute top-[6px] left-[12px]"
        >
          {label}
        </label>
        <input
          {...inputPropsFromRegister}
          ref={(el) => {
            inputPropsFromRegister.ref?.(el);
            if (typeof ref === 'function') {
              ref(el);
            } else if (ref) {
              ref.current = el;
            }
          }}
          type={type}
          name={name}
          placeholder={placeholder}
          className={cn(
            'p-3 pt-[18px] pb-[6px] bg-general_background_MIII_500_dark placeholder-general_text_MII_disabled rounded-lg border border-transparent focus:border-none focus:outline-general_MI_50 box-border text-[16px] leading-[20px] w-full text-general_text_MII_default',
            extraClasses,
          )}
          {...inputProps}
        />
      </div>
    );
  },
);

InputField.displayName = 'InputField';

export { InputField };
