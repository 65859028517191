import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { cn } from '@app/app/lib/cn';

const Button = (props) => {
  const {
    isLink = false,
    size = 'small',
    rounded = 'small',
    label,
    onClick,
    variant = 'primary',
    isFullWidth = false,
    isDisabled = false,
    leftIconSlot = '',
    extraClasses = [],
    link = '/',
    ...restProps
  } = props;

  function onClickButtonHandler(event) {
    event.preventDefault();
    onClick?.();
  }
  const sizeList = {
    middle: 'ui_button__size__middle',
    extra_middle: 'ui_button__size__extra_middle',
    large: 'ui_button__size__large',
    extra_small: 'ui_button__size__extra_small',
    small: 'ui_button__size__small',
  };
  const variantList = {
    primary: 'ui_button__variant__primary',
    outline: 'ui_button__variant__outline',
    noBorder: 'ui_button__variant__noBorder',
  };

  const roundedList = {
    small: 'ui_button__rounded__small',
    large: 'ui_button__rounded__large',
    middle: 'ui_button__rounded__middle',
  };

  const generateButtonClassName = ` ${sizeList[size]} ${variantList[variant]} ${roundedList[rounded]} ${isFullWidth ? 'ui_button__full_width' : ''}`;

  if (isLink) {
    return (
      <div>
        <Link to={link} className={'ui_button' + generateButtonClassName} {...restProps}>
          {label}
        </Link>
      </div>
    );
  }
  return (
    <div className={isFullWidth ? 'ui_button__full_width' : ''}>
      <button
        onClick={onClickButtonHandler}
        className={cn(
          `ui_button ${generateButtonClassName} ${isDisabled ? 'ui_button__disabled' : ''} gap-1.5`,
          extraClasses,
        )}
        disabled={isDisabled}
        {...restProps}
      >
        <span>{leftIconSlot ?? leftIconSlot}</span>
        {label}
      </button>
    </div>
  );
};

Button.prototype = {
  variant: PropTypes.oneOf(['primary', 'outline', 'noBorder']),
  isLink: PropTypes.bool,
  size: PropTypes.oneOf(['middle', 'small', 'extra_middle', 'extra_small', 'large']),
  rounded: PropTypes.oneOf(['small', 'large', 'middle']),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  link: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  leftIconSlot: PropTypes.element,
  extraClasses: PropTypes.array,
};

export default Button;
