import { ButtonHTMLAttributes, FC, forwardRef, SVGProps } from 'react';

import { cn } from '@app/app/lib';

import { BurgerIcon } from '../../components/ui/icons';

import { cvaIcon } from './elements';

type BurgerButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isOpen: boolean;
  activeIcon?: FC<SVGProps<SVGSVGElement>>;
  isTransparent?: boolean;
};

const BurgerButton: FC<BurgerButtonProps> = forwardRef<HTMLButtonElement, BurgerButtonProps>(
  (props, ref) => {
    const { isOpen, activeIcon: ActiveIcon, isTransparent = false, ...restProps } = props;

    return (
      <button type="button" aria-label="Open menu" ref={ref} {...restProps}>
        {!isOpen ? (
          <BurgerIcon
            className={cn(
              cvaIcon({ size: 'large' }),
              isTransparent ? 'fill-general_text_MII_default' : 'fill-general_text_MII_secondary_i',
            )}
          />
        ) : ActiveIcon ? (
          <ActiveIcon
            className={cn(
              cvaIcon({ size: 'large' }),
              isTransparent ? 'fill-general_text_MII_default' : 'fill-general_text_MII_secondary_i',
            )}
          />
        ) : null}
      </button>
    );
  },
);

BurgerButton.displayName = 'BurgerButton';

export { BurgerButton };
