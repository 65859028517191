import { cva } from 'class-variance-authority';

export const cvaContainer = cva(['settings-dialog-styles-cvaContainer', 'p-10']);

export const cvaTitle = cva([
  'settings-dialog-styles-cvaTitle',
  'text-font_desktop_display_display_small_32',
]);

export const cvaSubtitle = cva([
  'settings-dialog-styles-cvaSubtitle',
  'text-font_desktop_text_text_large mb-2 mt-6',
]);

export const cvaInputs = cva([
  'settings-dialog-styles-cvaInputs',
  'grid gap-2 grid-cols-3 mb-5 sm-max:grid-cols-1',
]);

export const cvaCheckboxContainer = cva([
  'settings-dialog-styles-cvaCheckboxContainer',
  'block mb-4',
]);

export const cvaSubmitButton = cva([
  'settings-dialog-styles-cvaSubmitButton',
  'max-w-[14rem] xs-max:max-w-full',
]);
