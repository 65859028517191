import { FC, useEffect, useState, useRef } from 'react';
import { PaymentType, ProductInit } from '@app/types/product.type';
import noScroll from 'no-scroll';
import {
  BackArrowIcon,
  CrossIcon,
  DuplicateIcon,
  PersonIcon,
  WalletIcon,
} from '@app/components/ui/icons';

import { Button } from '@app/components/ui';

import { TooltipTippy } from '@app/components/ui/tooltip/index';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { motion } from 'framer-motion';

import { UserData } from '@app/types/user.type';

import { useAppSelector } from '@app/hooks/use-store';

import { DetailForm } from './detail-form.block';

type PaymentPopupBlockProps = {
  chosenTariff?: ProductInit['products'][number] & { productTitle: string };
  buyTariff: (
    paymentType: PaymentType,
    tariff?: ProductInit['products'][number] & { productTitle: string },
  ) => Promise<void>;
  loading: boolean;
  close: () => void;
  hidePaypal: boolean;
  context: UserData | undefined;
};

const paymentProviderImages: Record<string, { srcSet: string; alt: string }> = {
  stripe: {
    srcSet: '/static/i/payment_logos.png, /static/i/payment_logos@2x.png 2x',
    alt: 'Stripe Payment Logo',
  },
  paypal: {
    srcSet: '/static/i/paypal_logo.png, /static/i/paypal_logo@2x.png 2x',
    alt: 'PayPal Payment Logo',
  },
  paytring: {
    srcSet: '/static/i/upi.png, /static/i/upi@2x.png 2x',
    alt: 'Paytring Payment Logo',
  },
  xendit: {
    srcSet: '/static/i/xendit.png, /static/i/xendit@2x.png 2x',
    alt: 'Xendit Payment Logo',
  },
};

const PaymentPopupBlock: FC<PaymentPopupBlockProps> = (props) => {
  const { chosenTariff, buyTariff, loading, close, hidePaypal, context } = props;
  const { isAuthenticated } = useAppSelector((state) => state.authState);
  const isBuyingRef = useRef(false);

  const userId = context?.user_ctx?.id;

  const checkAuth = () => {
    return !!userId;
  };

  const [isShowDetailForm, setIsShowDetailForm] = useState(false);
  const [formFields, setFormFields] = useState<{ name: string }[]>([]);
  const [submitUrlForDetailForm, setSubmitUrlForDetailForm] = useState('');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleTariffClick = (provider: { type: PaymentType; extra?: string[]; url: string }) => {
    if (provider.extra && isAuthenticated) {
      const fieldsWithPlaceholders = provider.extra.map((field) => ({
        name: field,
      }));

      setIsShowDetailForm(true);
      setFormFields(fieldsWithPlaceholders);
      setSubmitUrlForDetailForm(provider.url);
    } else {
      buyTariff(provider.type);
    }
  };

  // useEffect(() => {
  //   noScroll.on();
  //   return () => {
  //     noScroll.off();
  //   };
  // }, []);

  useEffect(() => {
    const autoBuyTariff = async () => {
      if (
        chosenTariff?.payment_provider.length === 1 &&
        isAuthenticated &&
        !chosenTariff.payment_provider[0].extra
      ) {
        if (!isBuyingRef.current) {
          try {
            isBuyingRef.current = true;
            await buyTariff(chosenTariff.payment_provider[0].type);
          } finally {
            isBuyingRef.current = false;
          }
        }
      }
    };

    autoBuyTariff();
  }, [chosenTariff, buyTariff, context]);

  if (!chosenTariff || isBuyingRef.current) return <></>;

  return (
    <div>
      <div
        className="fixed inset-0 z-10 "
        style={{ background: 'rgba(0, 0, 0, 0.8)' }}
        onClick={close}
      ></div>
      <motion.div
        initial={{ scale: 0, translateX: '-50%', translateY: '-50%' }}
        animate={{ scale: 1, translateX: '-50%', translateY: '-50%' }}
        exit={{ scale: 0, translateX: '-50%', translateY: '-50%' }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className="z-10 top-1/2 left-1/2 transform fixed "
      >
        <div className={'payment_types' + (loading ? ' loading' : '')}>
          <div
            className="w-6 h-6 top-4 right-4 text-general_text_MII_secondary_i absolute flex items-center justify-center cursor-pointer z-10"
            onClick={close}
          >
            <CrossIcon width={40} height={40} />
          </div>
          {isShowDetailForm ? (
            <>
              <h2 className="flex justify-center gap-5 text-[32px] leading-8 font-semibold">
                <PersonIcon
                  width={'33px'}
                  height={'30px'}
                  className="text-general_text_MII_secondary_i"
                />
                Provide your details to continue
              </h2>
              <div className="max-w-[400px] m-auto mt-6 mb-0">
                <DetailForm
                  fields={formFields}
                  submitUrl={submitUrlForDetailForm}
                  country={chosenTariff.price.currency === 'INR' ? 'India' : ''}
                />
                <div className="flex justify-center mb-6">
                  <Button
                    variant="noBorder"
                    label={'Back'}
                    onClick={() => setIsShowDetailForm(false)}
                    leftIconSlot={<BackArrowIcon width={13} height={13} />}
                    extraClasses={[
                      'ui_button--isBoxSizingBorderBox',
                      'ui_button--isHeight40',
                      'text-general_text_MII_secondary_i gap-3',
                    ]}
                  />
                </div>

                <div className="text-general_text_MII_secondary_ii text-[14px] leading-4 text-center mb-1">
                  If something went wrong or you're experiencing payment issues, please contact our
                  support:
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <a
                    href="mailto:support@wonderslide.com"
                    className="text-secondary_success_SI_500_success text-[14px] leading-4"
                  >
                    support@wonderslide.com
                  </a>
                  <TooltipTippy
                    contentSlot={copied ? 'Copied!' : 'Copy'}
                    interactive
                    hideOnClick={false}
                  >
                    <div className="cursor-pointer">
                      <CopyToClipboard text="support@wonderslide.com" onCopy={handleCopy}>
                        <DuplicateIcon
                          width={16}
                          height={16}
                          className="text-general_text_MII_secondary_i"
                        />
                      </CopyToClipboard>
                    </div>
                  </TooltipTippy>
                </div>
              </div>
            </>
          ) : (
            <div className="m-3 max-sm:m-0">
              <h2 className="flex gap-5 text-2xl font-semibold">
                <WalletIcon
                  width={'33px'}
                  height={'30px'}
                  className="text-general_text_MII_secondary_i text-[32px] leading-8"
                />
                Choose a payment method
              </h2>
              {/* <div className="payment_types_text">Click on the option to go to payment</div> */}
              <div className="text-general_text_MII_secondary_i flex flex-col text-base gap-1.75 mt-4 gap-2 items-center">
                {chosenTariff?.payment_provider.map((provider) => {
                  // Если нужно скрыть PayPal
                  if (hidePaypal && provider.type === 'paypal') return null;

                  const image = paymentProviderImages[provider.type];
                  return (
                    <div
                      key={provider.type}
                      className={`payment_types_btn ${provider.type} max-w-[400px] min-h-20 flex items-center justify-center`}
                      onClick={() => handleTariffClick(provider)}
                    >
                      <img
                        srcSet={image.srcSet}
                        alt={image.alt}
                        className="block w-[80%] h-[80%] object-contain"
                      />
                    </div>
                  );
                })}
              </div>
              <div className="payment_types_info">
                <div className="flex flex-col text-[14px] items-start text-general_text_MII_secondary_i">
                  Your plan:{' '}
                  <span className="text-secondary_success_SI_500_success text-2xl font-semibold leading-7 text-left underline decoration-transparent">
                    {chosenTariff?.productTitle}
                  </span>
                </div>
                <div className="flex">
                  <strong className="flex items-center gap-2 text-general_ico_MIV_500_default text-2xl font-semibold leading-7 text-left underline decoration-transparent mt-auto">
                    <span>{chosenTariff.price.currency_sign}</span>
                    <span>{chosenTariff.price.amount}</span>
                    {!!chosenTariff.profit && (
                      <span className="payment_types_profit ">
                        -&nbsp;{Number(chosenTariff.profit)}&nbsp;%
                      </span>
                    )}
                  </strong>
                </div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};
export default PaymentPopupBlock;
