import { ButtonHTMLAttributes, forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

import { Spinner } from '@app/shared/ui/spinner';

const cvaButtonVariants = cva(
  [
    'button-cvaButtonVariants',
    'relative inline-flex items-center gap-3 whitespace-nowrap box-border',
    'transition-colors',
    'disabled:pointer-events-none',
    '[&_svg]:pointer-events-none [&_svg]:shrink-0',
  ],
  {
    variants: {
      variant: {
        outline: [
          'text-general_MI_500_default border border-solid border-general_MI_500_default',
          'hover:border-general_MI_600_hover hover:text-general_MI_600_hover',
          'focus-visible:border-general_MI_600_hover focus-visible:text-general_MI_600_hover',
          'active:border-general_MI_700_pressed active:text-general_MI_700_pressed',
          'disabled:border-general_MI_800_disabled disabled:text-general_text_MII_disabled',
        ],
        primary: [
          'text-general_text_MII_default bg-general_MI_500_default',
          'hover:text-general_text_MII_hover hover:bg-general_MI_600_hover',
          'focus-visible:text-general_text_MII_hover focus-visible:bg-general_MI_600_hover',
          'active:text-general_text_MII_default active:bg-general_MI_700_pressed',
          'disabled:text-general_text_MII_disabled disabled:bg-general_MI_800_disabled',
        ],
        ghost: [
          'text-general_MI_500_default',
          'hover:text-general_MI_600_hover',
          'focus-visible:text-general_MI_600_hover',
          'active:general_MI_700_pressed',
          'disabled:text-general_text_MII_disabled',
        ],
        secondary: 'disabled:text-general_text_MII_disabled',
      },
      size: {
        text16: 'text-font_ds_title_button_buttons_16',
        text18: 'text-font_ds_title_body_body_18',
        default: 'h-10 px-4 text-font_ds_title_button_buttons_16',
        medium: 'h-12 px-4 text-font_ds_title_button_buttons_16',
        large: 'h-14 px-8 text-font_ds_title_body_body_20',
      },
      borderRadius: {
        '3xl': 'rounded-3xl',
        '2xl': 'rounded-2xl',
        xl: 'rounded-xl',
        lg: 'rounded-lg',
      },
      isFullWidth: {
        true: 'w-full',
        false: '',
      },
      justify: {
        start: 'justify-start',
        center: 'justify-center',
      },
      isActive: {
        true: null,
        false: null,
      },
    },
    compoundVariants: [
      {
        variant: 'secondary',
        isActive: true,
        className: ['text-general_MI_500_default', 'cursor-default pointer-events-none'],
      },
      {
        variant: 'secondary',
        isActive: false,
        className: [
          'text-general_text_MII_secondary_i',
          'hover:text-general_text_MII_secondary_ii',
          'focus-visible:text-general_text_MII_secondary_iii',
          'active:text-general_text_MII_secondary_ii',
        ],
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'default',
      isFullWidth: false,
      isActive: false,
      borderRadius: 'lg',
      justify: 'center',
    },
  },
);

const cvaContent = cva(['button-cvaContent', 'inline-flex items-center w-full gap-x-1.5 '], {
  variants: {
    isLoading: {
      true: 'invisible pointer-events-none',
      false: 'visible',
    },
  },
  defaultVariants: {
    isLoading: false,
  },
});

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof cvaButtonVariants>,
    VariantProps<typeof cvaContent> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isFullWidth,
      justify = 'center',
      borderRadius = 'lg',
      isLoading = false,
      asChild = false,
      isActive = false,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={clsx(
          cvaButtonVariants({
            variant,
            size,
            isFullWidth,
            borderRadius,
            justify,
            isActive,
          }),
          className,
        )}
        ref={ref}
        {...props}
      >
        <span className="inline-flex">
          <span className={cvaContent({ isLoading })}>{children}</span>
          <Spinner
            isVisible={isLoading}
            className="flex justify-center items-center absolute inset-0"
          />
        </span>
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, cvaButtonVariants };
