import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '@app/types/user.type';

type UserState = {
  user: UserData | null;
  redirectUrlForPayment: string | null;
};
const initialState: UserState = {
  user: null,
  redirectUrlForPayment: null,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    updateUserData: (state, action: PayloadAction<UserData | null>) => {
      const { payload } = action;
      state.user = payload;
    },
    setRedirectUrlForPayment: (state, action: PayloadAction<string>) => {
      state.redirectUrlForPayment = action.payload;
    },
    clearRedirectUrlForPayment: (state) => {
      state.redirectUrlForPayment = null;
    },
  },
});
export const { updateUserData, setRedirectUrlForPayment, clearRedirectUrlForPayment } =
  userSlice.actions;
export default userSlice.reducer;
