import { cva } from 'class-variance-authority';

export const cvaIcon = cva(['elements-cvaIcon'], {
  variants: {
    size: {
      xSmall: 'h-3 w-3',
      small: 'h-4 w-4',
      medium: 'h-5 w-5',
      default: 'h-6 w-6',
      large: 'h-8 w-8',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export const cvaScrollbar = cva([
  'elements-cvaScrollbar',
  'scrollbar-thin scrollbar-w-1 scrollbar-thumb-general_background_MIII_400_dark scrollbar-track-transparent scrollbar-thumb-rounded-sm',
]);
