import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'stage-header-styles-cvaRoot',
  'pt-4 px-5 pb-6 h-[2rem]',
  'bg-general_background_MIII_500_dark',
]);

export const cvaContent = cva([
  'stage-header-styles-cvaContent',
  'flex items-center justify-between',
]);

export const cvaNavigation = cva(['stage-header-styles-cvaNavigation', 'flex items-center gap-2']);

export const cvaArrowIcon = cva([
  'stage-header-styles-cvaArrowIcon',
  'h-3 w-3 fill-general_MI_800_disabled',
]);

export const cvaNavigationItem = cva(
  ['stage-header-styles-cvaNavigationItem', 'text-font_ds_title_body_body_14 pointer-events-auto'],
  {
    variants: {
      isActive: {
        true: 'text-general_MI_500_default cursor-default',
        false: 'text-general_text_MII_disabled cursor-pointer',
      },
    },
    defaultVariants: {
      isActive: false,
    },
  },
);
