import { FC, useRef, useEffect } from 'react';
import { Link, NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import { differenceInDays, parseISO } from 'date-fns';
import { VariantProps } from 'class-variance-authority';
import { Root } from '@radix-ui/react-dialog';

import { CANNY_URL } from '@app/utils/constants';
import { route_path } from '@app/utils/route_path';

import { useLogOutMutation } from '@app/store/api/user.api';

import { BxGridAltIcon, CrossIcon, LogoIcon } from '@app/components/ui/icons';
import { Container } from '@app/shared/ui/container';
import { Button } from '@app/shared/ui/button';
import { cvaIcon } from '@app/shared/ui/elements';

import { useCannyLink } from '@app/hooks/use-canny-link';

import { BurgerButton } from '@app/shared/ui/burger-button';

import { cn } from '@app/app/lib';

import { MobileMenu } from '@app/widgets/header/ui/mobile-menu';

import { useAppDispatch, useAppSelector } from '@app/hooks/use-store';

import type { CommonOutletContext } from '@app/pages/common.outlet';

import { useMediaQuery } from 'usehooks-ts';

import { navLinks, navLinksV2 } from '../model/static-data';

import t from '../../../../lib/lng'; // TODO: Change to i18n

import { isHeaderMenuOpen, toggleHeaderMenu } from '../model/header.slice';

import { screens } from '../../../../../../config/tailwind';

import { HeaderMenu } from './header-menu';

import {
  cvaActionsMenu,
  cvaContainer,
  cvaDaysLeft,
  cvaHeaderMainContent,
  cvaHeaderNavMenu,
  cvaLogo,
  cvaLogoLink,
  cvaMainHeader,
  cvaMenuItem,
  cvaRoot,
} from './header-styles';

type HeaderProps = VariantProps<typeof cvaRoot> & {
  isOpen?: boolean;
  onToggle?: () => void;
  isWithStaged?: boolean;
};

export const Header: FC<HeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.authState);
  const { isTransparent, isWithStaged = false, isOpen, onToggle } = props;

  const isMenuOpen = useAppSelector(isHeaderMenuOpen);
  const { context, user_ctx, commonContext } = useOutletContext<CommonOutletContext>();
  const isMobile = useMediaQuery(`(max-width: ${screens.screens.md})`);

  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [logoutQuery] = useLogOutMutation();

  useEffect(() => {
    if (isMenuOpen) {
      window.scrollTo(0, 0);
    }
  }, [isMenuOpen]);

  const isTransparentStyles = !isMenuOpen && !!isTransparent;

  const handleNavigationClick = () => dispatch(toggleHeaderMenu(false));
  const handleToggleMenuOpen = () => dispatch(toggleHeaderMenu(!isMenuOpen));

  const logOutHandler = () => {
    logoutQuery().then(() => {
      handleNavigationClick();
      navigate(route_path.main);
    });
  };

  const cannyLink = useCannyLink(user_ctx);

  const daysLeft =
    context?.product_balance &&
    context?.product_balance.is_active &&
    context?.product_balance.product.is_trial &&
    context?.product_balance.expire
      ? differenceInDays(parseISO(context?.product_balance.expire), new Date())
      : null;

  return (
    <Root open={isMenuOpen}>
      <MobileMenu
        daysLeft={daysLeft}
        onNavigationClick={handleNavigationClick}
        onLogOut={logOutHandler}
      />
      {/* TODO: Remove header-font */}
      <header className={cn(cvaRoot({ isTransparent: isTransparentStyles }), 'header-font')}>
        <div ref={headerRef} className={cvaMainHeader()}>
          <Container className={cvaContainer()}>
            {/* Logo */}
            <Link to={isAuthenticated ? '/create/' : '/'} className={cvaLogoLink()}>
              <LogoIcon className={cvaLogo()} />
            </Link>

            {/* Header main content*/}
            <div className={cvaHeaderMainContent()}>
              {/* Navigations */}
              <nav className={cvaHeaderNavMenu()}>
                {commonContext?.boxed
                  ? navLinksV2.map(({ to, title, id }) => (
                      <NavLink
                        key={id}
                        className={({ isActive }) =>
                          cvaMenuItem({ isActive, isTransparent: isTransparentStyles })
                        }
                        to={to}
                      >
                        {title}
                      </NavLink>
                    ))
                  : navLinks.map(({ to, title, id }) => (
                      <NavLink
                        key={id}
                        className={({ isActive }) =>
                          cvaMenuItem({ isActive, isTransparent: isTransparentStyles })
                        }
                        to={to}
                      >
                        {title}
                      </NavLink>
                    ))}
                {!commonContext?.boxed && (
                  <Link
                    data-canny-link="header"
                    className={cvaMenuItem({ isTransparent: isTransparentStyles })}
                    onClick={cannyLink}
                    to={CANNY_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Suggest feature
                  </Link>
                )}
              </nav>

              {/* Header actions */}
              <div className={cvaActionsMenu()}>
                {!isAuthenticated && (
                  <>
                    <Button borderRadius="3xl" asChild>
                      <Link to="/register/">{t.Signup}</Link>
                    </Button>

                    <Link
                      to="/login/"
                      className={cvaMenuItem({
                        isDotHidden: true,
                        isTransparent: isTransparentStyles,
                      })}
                    >
                      {t.Login}
                    </Link>
                  </>
                )}

                {isAuthenticated && (
                  <>
                    {/* Days left */}
                    {daysLeft !== null && (
                      <div className={cvaDaysLeft({ isTransparent: isTransparentStyles })}>
                        {daysLeft > 0
                          ? t.formatString(t.trialExpiresInDays, daysLeft)
                          : t.Less1DayTrial}
                      </div>
                    )}

                    {!context?.product_balance.is_active && (
                      <Button borderRadius="3xl" variant="outline" asChild className="md:hidden">
                        <Link to="/pricing/">{t.upgradeSubscription}</Link>
                      </Button>
                    )}

                    {/* Create new button */}
                    <Button
                      variant={isTransparentStyles ? 'primary' : 'outline'}
                      borderRadius="3xl"
                      asChild
                    >
                      <Link to="/create/">{t.Create}</Link>
                    </Button>

                    {/* My projects*/}
                    <NavLink
                      to="/projects/"
                      className={({ isActive }) =>
                        cvaMenuItem({
                          isActive,
                          isTransparent: isTransparentStyles,
                          isDotHidden: true,
                        })
                      }
                    >
                      <BxGridAltIcon className={cvaIcon()} />
                      <span>My presentations</span>
                    </NavLink>

                    {/* Account menu */}
                    <div className={cn(cvaMenuItem({ isTransparent: isTransparentStyles }))}>
                      <HeaderMenu />
                    </div>
                  </>
                )}
              </div>
            </div>

            {isMobile ? (
              <BurgerButton
                isOpen={isMenuOpen}
                activeIcon={CrossIcon}
                isTransparent={isTransparentStyles}
                onClick={handleToggleMenuOpen}
                className="ml-auto md:hidden pointer-events-auto"
              />
            ) : (
              <BurgerButton
                isOpen={isOpen ?? false}
                activeIcon={CrossIcon}
                onClick={onToggle}
                className={cn(
                  'ml-6 pointer-events-auto',
                  !isOpen ? (isWithStaged ? 'opacity-0' : 'md:hidden') : null,
                )}
              />
            )}
          </Container>
        </div>
      </header>
    </Root>
  );
};
