import { FC } from 'react';
import { useOutletContext } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Content, Overlay, Portal, Description, Title } from '@radix-ui/react-dialog';
import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { useCannyLink } from '@app/hooks/use-canny-link';
import { CANNY_URL } from '@app/utils/constants';
import { route_path } from '@app/utils/route_path';
import { Button } from '@app/shared/ui/button';
import { cvaIcon } from '@app/shared/ui/elements';
import { CommonOutletContext } from '@app/pages/common.outlet';

import { useAppSelector } from '@app/hooks/use-store';

import { BxGridAltIcon, CogIcon, CreditCardIcon, LogoutIcon } from '../../../components/ui/icons';
import t from '../../../../lib/lng';

import { navLinks, navLinksV2 } from '../model/static-data';

import { cvaActionsMenuMobile, cvaMenuContentMobile, cvaNavMenuMobile } from './mobile-menu-styles';
import { cvaDaysLeft, cvaMenuItem } from './header-styles';

type MobileMenuProps = {
  daysLeft: number | null;
  onLogOut: () => void;
  onNavigationClick: () => void;
};

export const MobileMenu: FC<MobileMenuProps> = (props) => {
  const { isAuthenticated } = useAppSelector((state) => state.authState);
  const { daysLeft, onLogOut, onNavigationClick } = props;

  const { setShowPayments, setShowSettings, context, user_ctx, commonContext } =
    useOutletContext<CommonOutletContext>();

  const userData = context;
  const cannyLink = useCannyLink(user_ctx);

  return (
    <Portal>
      <Overlay />
      <Content className={cvaMenuContentMobile()}>
        {/* TODO: Accessibility component */}
        <VisuallyHidden>
          <Title />
        </VisuallyHidden>
        <VisuallyHidden>
          <Description />
        </VisuallyHidden>
        {/* Navigations */}
        <nav className={cvaNavMenuMobile()}>
          {commonContext?.boxed
            ? navLinksV2.map(({ to, title, id }) => (
                <NavLink
                  key={id}
                  className={({ isActive }) => cvaMenuItem({ isActive, isTransparent: false })}
                  to={to}
                >
                  {title}
                </NavLink>
              ))
            : navLinks.map(({ to, title, id }) => (
                <NavLink
                  key={id}
                  className={({ isActive }) =>
                    cvaMenuItem({ isActive, isTransparent: false, isDotHidden: true })
                  }
                  to={to}
                  onClick={onNavigationClick}
                >
                  {title}
                </NavLink>
              ))}
          {!commonContext?.boxed && (
            <>
              <Link
                to={route_path.blog}
                className={cvaMenuItem({ isDotHidden: true })}
                onClick={onNavigationClick}
              >
                {t.Blog}
              </Link>
              <Link
                data-canny-link="header"
                className={cvaMenuItem({ isDotHidden: true })}
                onClick={cannyLink}
                to={CANNY_URL}
                target="_blank"
                rel="noreferrer"
              >
                Suggest feature
              </Link>
            </>
          )}
        </nav>

        {/* Header actions */}
        <div className={cvaActionsMenuMobile()}>
          {!isAuthenticated && (
            <>
              <Button borderRadius="3xl" asChild>
                <Link to="/register/">{t.Signup}</Link>
              </Button>

              <Link
                to="/login/"
                className={cvaMenuItem({
                  isDotHidden: true,
                })}
              >
                {t.Login}
              </Link>
            </>
          )}

          {isAuthenticated && (
            <>
              {/* Days left */}
              {daysLeft !== null && (
                <div className={cvaDaysLeft()}>
                  {daysLeft > 0 ? t.formatString(t.trialExpiresInDays, daysLeft) : t.Less1DayTrial}
                </div>
              )}

              {!userData?.product_balance.is_active && (
                <Button borderRadius="3xl" variant="outline" asChild className="md:hidden">
                  <Link to="/pricing/">{t.upgradeSubscription}</Link>
                </Button>
              )}

              {/* Create new button */}
              <Button variant="outline" borderRadius="3xl" asChild onClick={onNavigationClick}>
                <Link to="/create/">{t.Create}</Link>
              </Button>

              {/* My projects*/}
              <NavLink
                to="/projects/"
                className={({ isActive }) =>
                  cvaMenuItem({
                    isActive,
                    isDotHidden: true,
                  })
                }
                onClick={onNavigationClick}
              >
                <BxGridAltIcon className={cvaIcon()} />
                <span>My presentations</span>
              </NavLink>
            </>
          )}
        </div>

        {isAuthenticated && (
          <>
            {!commonContext?.boxed && (
              // Payments button
              <button
                type="button"
                className={cvaMenuItem({ isDotHidden: true })}
                onClick={() => setShowPayments(true)}
              >
                <CreditCardIcon className={cvaIcon({ size: 'large' })} />
                <span>{t.Payments}</span>
              </button>
            )}

            {/* Settings button */}
            <button
              type="button"
              className={cvaMenuItem({ isDotHidden: true })}
              onClick={() => setShowSettings(true)}
            >
              <CogIcon className={cvaIcon({ size: 'large' })} />
              <span>Settings</span>
            </button>

            {/* Logout button */}
            <a
              href={route_path.logout}
              className={cvaMenuItem({ isDotHidden: true })}
              onClick={onLogOut}
            >
              <LogoutIcon className={cvaIcon({ size: 'large' })} />
              <span>Log out</span>
            </a>

            {user_ctx?.is_superuser && (
              <Button variant="primary" asChild>
                <a href={route_path.admin}>Admin</a>
              </Button>
            )}
          </>
        )}
      </Content>
    </Portal>
  );
};
