// TODO: Refactor component
import { FC } from 'react';

type ChangesSavedPopup = { close: () => void };

export const ChangesSavedPopup: FC<ChangesSavedPopup> = (props) => {
  const { close } = props;
  setTimeout(() => {
    close();
  }, 3000);
  return (
    <div className="popup_changes_saved">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
          fill="#00BA88"
        />
        <path d="M11 11H13V17H11V11ZM11 7H13V9H11V7Z" fill="#00BA88" />
      </svg>
      Changes saved
    </div>
  );
};
