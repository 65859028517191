import { Outlet, useOutletContext } from 'react-router';

import { CommonOutletContext } from '@app/pages/common.outlet';
import { Header } from '@app/widgets/header';
import { FC } from 'react';

export const DefaultLayout: FC = () => {
  const context = useOutletContext<CommonOutletContext>();
  return (
    <>
      <Header isTransparent={false} />
      <main className="min-h-[calc(100vh-100px)]">
        <Outlet context={context} />
      </main>
    </>
  );
};
