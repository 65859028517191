import { createInstance } from '@amplitude/analytics-browser';
import TagManager from '@sooro-io/react-gtm-module';

import { BrowserOptions } from '@amplitude/analytics-types/lib/esm/config';

import { BrowserClient } from '@amplitude/analytics-types';

import { eventsConfig } from '@app/app/analytics/config';

import { cookie } from '../lib/cookie';

import {
  AnalyticsPlatform,
  EventNames,
  PlatformName,
  EventNameConfig,
  EventsPayload,
} from './types';

type Mode = 'development' | 'production';

export class AnalyticsService {
  private static instance: AnalyticsService;

  private readonly eventConfig: EventNameConfig;

  private readonly platforms: Record<PlatformName, AnalyticsPlatform | undefined>;
  private mode: Mode = 'production';

  private constructor(config: EventNameConfig) {
    this.eventConfig = config;
    this.platforms = {
      GTM: undefined,
      Amplitude: undefined,
    };
  }

  public static getInstance(config: EventNameConfig): AnalyticsService {
    if (!AnalyticsService.instance) {
      if (!config) {
        throw new Error('Initial configuration is required for the first initialization.');
      }
      AnalyticsService.instance = new AnalyticsService(config);
    }
    return AnalyticsService.instance;
  }

  public registerPlatform(platformName: PlatformName, platform: AnalyticsPlatform) {
    this.platforms[platformName] = platform;
  }

  public switchMode = (mode: Mode) => {
    this.mode = mode;
  };

  public emitEvent<T extends EventNames>(eventName: T, eventPayload: EventsPayload[T]) {
    const eventConfig = this.eventConfig[eventName];

    if (!eventConfig) {
      console.error(`Event ${eventName} is not defined in the configuration.`);
      return;
    }

    for (const platformName in eventPayload) {
      const platform = this.platforms[platformName as PlatformName];

      if (!platform) {
        if (this.mode === 'development') {
          console.groupCollapsed(
            `%c🚫 Platform %c${platformName} %cis not registered.`,
            'color: red; font-weight: bold;',
            'color: orange; font-weight: bold;',
            'color: inherit;',
          );
          console.trace();
          console.groupEnd();
        }
        return;
      } else {
        if (this.mode === 'development') {
          const emoji = platformName === 'GTM' ? '📦' : '📊';

          // Проверяем, есть ли в имени события слово 'error' (независимо от регистра)
          const eventName = eventConfig[platformName as keyof EventNameConfig[T]];
          const isErrorEvent = /error/i.test(eventName); // (игнорируем регистр)

          console.info(
            `%c${emoji} Event sent to platform:%c ${platformName} %c| %cEvent: "${eventName}"`,
            // Общий стиль для всей строки
            'background-color: #2ee84445; color: white; padding: 2px 2px; font-weight: bold; font-size: 14px; border-radius: 6px;',

            // Стиль для имени платформы (выделено отдельным цветом)
            'color: orange; font-weight: bold;',

            // Для разделителя " | "
            'color: inherit;',

            // Стиль для события (если это ошибка, выделяем красным)
            `color: ${isErrorEvent ? 'red' : 'green'}; font-weight: bold; white-space: pre-line;`,
          );

          console.groupCollapsed(
            `%c🔍 Payload details for 👆`,
            'color: white; font-weight: bold; background-color: teal; padding: 2px 2px; font-size: 14px; border-radius: 6px',
          );

          console.info(
            JSON.stringify(
              eventPayload[platformName],
              (key, value) => {
                if (typeof value === 'object' && value !== null) {
                  return JSON.parse(JSON.stringify(value));
                }
                return value;
              },
              2,
            ),
          );

          // Выводим объект для JS (скрытый)
          console.groupCollapsed(
            '%c🕵️‍♂️ JS detail:',
            'color: teal; font-style: italic; font-weight: bold;',
          );
          console.dir(eventPayload[platformName], { depth: 3 });
          console.groupEnd();

          console.groupEnd();
        }
        platform.sendEvent(
          eventConfig[platformName as keyof EventNameConfig[T]],
          eventPayload[platformName],
        );
      }
    }
  }
}

export class AmplitudePlatform implements AnalyticsPlatform {
  private readonly amplitudeInstance: BrowserClient;

  constructor() {
    this.amplitudeInstance = createInstance();
  }

  public async init(apiKey: string, options: BrowserOptions) {
    try {
      await this.amplitudeInstance.init(apiKey, options).promise;

      const did = this.amplitudeInstance.getDeviceId();

      if (did) {
        cookie.set(
          'did',
          did,
          undefined,
          undefined,
          undefined,
          '/',
          undefined,
          undefined,
          'Strict',
        );
      }
    } catch (e) {
      console.error(`Error during Amplitude initialization: ${e}`);
    }
  }

  public getInstance() {
    return this.amplitudeInstance;
  }

  public sendEvent<T>(event: string, eventPayload: T): void {
    // TODO: fix type
    this.amplitudeInstance.logEvent(event, eventPayload as Record<string, unknown>);
  }
}

export class GTMPlatform implements AnalyticsPlatform {
  private static countriesCodeBlacklist = ['IR'];
  private static instance: GTMPlatform | null = null;

  private currentCountry: string = '';

  constructor() {
    if (GTMPlatform.instance) {
      return GTMPlatform.instance;
    }
    GTMPlatform.instance = this;
  }

  public setCountry(country: string): void {
    this.currentCountry = country;
  }

  public async sendEvent<T>(eventName: string, eventPayload: T) {
    if (GTMPlatform.countriesCodeBlacklist.includes(this.currentCountry)) return;
    TagManager.dataLayer({ dataLayer: { event: eventName, ...eventPayload } });
  }
}

const analytics = AnalyticsService.getInstance(eventsConfig);

const gtmInstance = new GTMPlatform();
const amplitudeInstance = new AmplitudePlatform();

export { analytics, amplitudeInstance, gtmInstance };
