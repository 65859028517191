import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface TooltipProps extends Omit<TippyProps, 'children'> {
  children: React.ReactElement;
  contentSlot: React.ReactNode;
  className?: string;
  theme?: string;
}

export const TooltipTippy: React.FC<TooltipProps> = ({
  children,
  contentSlot,
  className = '',
  theme = 'custom-tailwind',
  ...props
}) => {
  return (
    <Tippy
      content={contentSlot}
      arrow={true} // Показываем стрелку
      interactive={true} // не исчезает при наведении
      className={`tooltip-class ${className}`}
      theme={theme}
      {...props} // Другие пропсы для кастомизации Tippy
    >
      {children}
    </Tippy>
  );
};
