import { cookie } from '@app/app/lib/cookie';

import t from './lng';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

/** SERVICE MESSAGES
 * usage: serviceMessage.showMessage('message text', [type]);
 */
const serviceMessage = (function () {
  const msgWrap = document.createElement('div');
  msgWrap.classList = 'service_msgs_wrap';
  document.body.append(msgWrap);

  return {
    showMessage: function (msgText, type = '') {
      let newMsg = document.createElement('div'),
        newMsgClose = document.createElement('div');
      newMsg.classList = 'service_msg ' + type;
      newMsg.innerText = msgText;
      newMsgClose.classList = 'service_msg__close';
      newMsgClose.innerHTML = '&times;';
      newMsgClose.addEventListener('click', function () {
        serviceMessage.removeMessage(this.closest('.service_msg'));
      });

      newMsg.append(newMsgClose);
      msgWrap.append(newMsg);
      serviceMessage.autoClose(newMsg);
    },
    removeMessage: function (msg) {
      msg.style.opacity = 0;
      setTimeout(() => {
        msg.parentElement && msg.parentElement.removeChild(msg);
      }, 600);
    },
    autoClose: function (msg) {
      setTimeout(() => {
        serviceMessage.removeMessage(msg);
      }, 5000);
    },
  };
})();

function wordCase(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  number = Math.abs(number);
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

function loadScript(src, callback) {
  if (document.querySelector(`script[src="${src}"]`)) {
    if (typeof callback === 'function') callback();
    return;
  }
  const script = document.createElement('script');
  if (typeof callback === 'function') {
    script.onload = callback;
  }
  script.src = src;
  document.body.appendChild(script);
}

function getFontValue(font, shape) {
  if (font === t.AutomaticSelection) {
    if ([9, 5, 21, 104].includes(shape)) return 'Nunito';
    else return 'Open Sans';
  } else return font;
}

function xhrHeaders() {
  return {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRFToken': cookie.get('csrftoken') || '',
  };
}

function contrast(rgb1, rgb2) {
  let lum1 = luminance(...rgb1);
  let lum2 = luminance(...rgb2);
  let brightest = Math.max(lum1, lum2);
  let darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);

  function luminance(r, g, b) {
    const RED = 0.2126;
    const GREEN = 0.7152;
    const BLUE = 0.0722;
    const GAMMA = 2.4;
    let a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
    });
    return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
  }
}

const userLocalParams = {
  get: (id, p) => {
    const params = JSON.parse(window.localStorage.getItem('userParams'));
    if (params && params[id] && params[id][p]) return params[id][p];
    return null;
  },

  set: (id, p, v) => {
    let params = JSON.parse(window.localStorage.getItem('userParams'));
    if (params) {
      if (params[id]) {
        params[id][p] = v;
      } else {
        params[id] = {
          [p]: v,
        };
      }
    } else {
      params = {
        [id]: {
          [p]: v,
        },
      };
    }
    window.localStorage.setItem('userParams', JSON.stringify(params));
  },

  delete: (id, p) => {
    let params = JSON.parse(window.localStorage.getItem('userParams'));
    if (params && params[id] && params[id][p]) {
      delete params[id][p];
      window.localStorage.setItem('userParams', JSON.stringify(params));
    }
  },

  clear: () => {
    window.localStorage.removeItem('userParams');
  },
};

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function getRandomValueFromArray(arr, limit = arr.length) {
  if (!Array.isArray(arr) || arr.length === 0) {
    throw new Error('Передайте непустой массив');
  }

  const effectiveLimit = Math.min(limit, arr.length);

  const randomIndex = Math.floor(Math.random() * effectiveLimit);
  return arr[randomIndex];
}

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function updateViewport(pathname) {
  const viewportMeta = document.querySelector('meta[name="viewport"]');

  // Если ширина окна больше 1024 пикселей или путь не задан, выходим из функции
  if (window.innerWidth > 1024 || !pathname) return;

  // Если путь соответствует /create или /files, устанавливаем фиксированную ширину
  if (pathname.match(/\/create|files/)) {
    viewportMeta.setAttribute('content', 'width=1024');
  } else {
    // Для всех других маршрутов (включая главную), устанавливаем адаптивный режим
    viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1');
  }
}

export {
  getRandomInt,
  serviceMessage,
  wordCase,
  loadScript,
  getFontValue,
  xhrHeaders,
  contrast,
  userLocalParams,
  getRandomValueFromArray,
  isEmpty,
  wait,
  updateViewport,
};
