import { EventNameConfig } from '@app/app/analytics/types';

export const eventsConfig: EventNameConfig = {
  page_view: {
    Amplitude: 'pageview',
  },
  login: {
    GTM: 'login',
  },
  sign_up: {
    GTM: 'sign_up',
    Amplitude: 'service registration',
  },
  trial_start: {
    GTM: 'trial_start',
  },
  user_data: {
    GTM: 'user_data',
  },
  click_generation_start: {
    GTM: 'click_generation_start',
    Amplitude: 'click generation start',
  },
  style_change_save: {
    GTM: 'style_change_save',
    Amplitude: 'style change save',
  },
  file_generation_start: {
    GTM: 'file_generation_start',
  },
  file_generation_first_slide: {
    Amplitude: 'file generation first slide',
  },
  file_generation_done: {
    GTM: 'file_generation_done',
    Amplitude: 'file generation done',
  },
  scratch_generation_first_slide_start: {
    GTM: 'scratch_generation_first_slide_start',
    Amplitude: 'scratch generation first slide start',
  },
  scratch_generation_first_slide_done: {
    GTM: 'scratch_generation_first_slide_done',
    Amplitude: 'scratch generation first slide done',
  },
  generation_slide_start: {
    GTM: 'generation_slide_start',
    Amplitude: 'generation slide start',
  },
  generation_slide_done: {
    GTM: 'generation_slide_done',
    Amplitude: 'generation slide done',
  },
  design_editing: {
    GTM: 'design_editing',
    Amplitude: 'design editing click',
  },
  design_editing_done: {
    Amplitude: 'design editing done',
  },
  file_rename: {
    GTM: 'file_rename',
    Amplitude: 'file rename',
  },
  trial_popup_close: {
    GTM: 'trial_popup_close',
    Amplitude: 'trial popup close',
  },
  file_delete: {
    GTM: 'file_delete',
    Amplitude: 'file delete',
  },
  click_file_download: {
    GTM: 'click_file_download',
  },
  file_create_duplicate: {
    GTM: 'file_create_duplicate',
    Amplitude: 'file create duplicate',
  },
  trial_popup_to_checkout: {
    GTM: 'trial_popup_to_checkout',
    Amplitude: 'trial popup to checkout',
  },
  click_plan: {
    Amplitude: 'click plan',
  },
  edit_slide_advanced_mode_switch: {
    Amplitude: 'slide edit_mode switch',
  },
  scratch_create_open: {
    GTM: 'scratch_create_open',
    Amplitude: 'scratch create open',
  },
  share: {
    GTM: 'share',
    Amplitude: 'share',
  },
  trial_end: {
    GTM: 'trial_end',
  },
  file_upload_open: {
    GTM: 'file_upload_open',
    Amplitude: 'file upload open',
  },
  file_upload: {
    GTM: 'file_upload',
  },
  demo_file_upload: {
    GTM: 'demo_file_upload',
  },
  click_demo_file_download: {
    GTM: 'click_demo_file_download',
  },
  demo_file_download: {
    GTM: 'demo_file_download',
  },
  file_click_download: {
    Amplitude: 'file click download',
  },
  transaction_click_tariff: {
    Amplitude: 'transaction click tariff',
  },
  file_download: {
    GTM: 'file_download',
  },
  click_share_button: {
    Amplitude: 'click share button',
  },
  begin_checkout: {
    GTM: 'begin_checkout',
  },
  file_upload_demo_start: {
    Amplitude: 'file upload demo start',
  },
  file_upload_start: {
    Amplitude: 'file upload start',
  },
  file_uploaded: {
    Amplitude: 'file uploaded',
  },
  service_onboarding_demofile: {
    Amplitude: 'service onboarding demofile',
  },
  shared_file_pageview: {
    Amplitude: 'shared file pageview',
  },
  // Error events
  service_error_system: {
    Amplitude: 'service error system',
  },
  service_error_preview: {
    Amplitude: 'service error preview',
  },
  track_error: {
    GTM: 'track_error',
  },
  payment_refuse_survey_answer: {
    Amplitude: 'payment_refuse survey answer',
  },
  payment_refuse_survey_close: {
    Amplitude: 'payment_refuse survey close',
  },
  payment_refuse_survey_to_checkout: {
    Amplitude: 'payment_refuse survey to checkout',
  },
};
