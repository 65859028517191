export const screens = {
  screens: {
    xxs: '375px',
    xs: '640px',
    sm: '768px',
    semiMd: '1024px',
    md: '1280px',
    lg: '1440px',
    xlg: '1920px',

    'xlg-max': { max: '1919px' },
    'lg-max': { max: '1439px' },
    'md-max': { max: '1279px' },
    'semiMd-max': { max: '1023px' },
    'sm-max': { max: '767px' },
    'xs-max': { max: '639px' },
    'xxs-max': { max: '374px' },
  },
};
