import { FC, useEffect } from 'react';

import { analytics } from '@app/app/analytics';

import { TrialPopupType } from '@app/app/analytics/types';

import TariffsBlock from '@app/pages/pricing/blocks/tariffs.block';

import { UserData } from '@app/types/user.type';

import {
  CheckedCircleIcon,
  CrossIcon,
  DownloadCloudIcon,
  LockIcon,
  RocketIcon,
  SpinnerIcon,
} from '@app/components/ui/icons';

import noScroll from 'no-scroll';

import { useAppSelector } from '../../../hooks/use-store';
import { TrialType } from '../../../types/trial.type';

type TrialPopupProps = {
  close: () => void;
  type: TrialType;
  context: UserData | undefined;
  onClickDownload: () => void;
};

const TrialPopup: FC<TrialPopupProps> = (props) => {
  const { close, type, context, onClickDownload } = props;
  const { isGlobalRequest } = useAppSelector((state) => state.commonState);

  const onClose = () => {
    const typeToPopupTypeMap: Record<string, TrialPopupType> = {
      'download trial': 'download_trial_popup',
      'download expired': 'download_trial_popup',
      logo: 'logo_trial_popup',
      style: 'style_trial_popup',
      image: 'slide_edit_trial_popup',
      upgrade: 'slide_edit_trial_popup',
      'trial limit': 'projects_trial_popup',
    };

    const popupType: TrialPopupType = typeToPopupTypeMap[type];

    analytics.emitEvent('trial_popup_close', {
      GTM: { popup_name: popupType },
      Amplitude: { 'popup name': popupType },
    });
    close?.();
  };

  useEffect(() => {
    noScroll.on();
    return () => {
      noScroll.off();
    };
  }, []);

  return (
    <div className="modal modal-trial backdrop-blur-sm ">
      <div className="modal_overlay" onClick={onClose}></div>
      <div className="modal_window">
        <div className="modal_close" onClick={onClose}>
          <CrossIcon className="text-general_text_MII_secondary_i" width={32} height={32} />
        </div>

        <div className={'modal-trial_header' + (isGlobalRequest ? ' disabled ' : '')}>
          {type === 'download trial' && (
            <>
              <div className="modal-trial_title">
                {!isGlobalRequest && (
                  <div className="flex items-center gap-3">
                    <DownloadCloudIcon
                      className="text-general_text_MII_secondary_i"
                      width={32}
                      height={32}
                    />
                    <span>Upgrade to download</span>
                  </div>
                )}
                {isGlobalRequest && (
                  <>
                    <CheckedCircleIcon className="text-general_text_MII_secondary_i" />
                    Downloaded 3 slides out of 10
                  </>
                )}
              </div>
              <div className="modal-trial_text">
                <p>During the trial period, your download is limited to 3 slides.</p>
                <p>To download the full presentation, please purchase any plan.</p>
              </div>
              <div
                className={
                  'modal-trial_btn btn-outlined' +
                  (isGlobalRequest
                    ? ' modal-trial_btn-preparing animate-pulse opacity-80 hover:opacity-80'
                    : '')
                }
                onClick={() => onClickDownload()}
              >
                {isGlobalRequest && (
                  <>
                    <SpinnerIcon className="text-general_MI_700_pressed" />
                    Preparing your file
                  </>
                )}
                {!isGlobalRequest && <>Download</>}
              </div>
            </>
          )}
          {type === 'download expired' && (
            <>
              <div className="modal-trial_title">
                <DownloadCloudIcon className="text-general_text_MII_secondary_i" />
                Upgrade to download
              </div>
              <div className="modal-trial_subtitle">
                <p>Sorry, but you cannot download the presentation on your current plan.</p>
                <p>To download the full presentation, please purchase any plan.</p>
              </div>
            </>
          )}
          {type === 'upgrade' && (
            <>
              <div className="modal-trial_title">
                <RocketIcon className="text-general_text_MII_secondary_i" />
                Upgrade your plan
              </div>
              <div className="modal-trial_text">
                <p>And unlock the full potential of Wonderslide</p>
              </div>
            </>
          )}
          {(type === 'logo' || type === 'image' || type === 'style') && (
            <>
              <div className="modal-trial_title">
                <LockIcon className="text-general_text_MII_secondary_i" />
                Feature is locked
              </div>
              <div className="modal-trial_text">
                <p>Sorry, but you cannot use this feature on your current plan.</p>
                <p>Purchase one of our plans to unlock all features</p>
              </div>
            </>
          )}
          {type === 'trial limit' && (
            <>
              <div className="modal-trial_title">
                <RocketIcon className="text-general_text_MII_secondary_i" />
                Upgrade your plan
              </div>
              <div className="modal-trial_text">
                <p>You have reached the presentation creation limit.</p>
                <p>
                  Upgrade to one of our plans to create an unlimited number of&nbsp;presentations.
                </p>
              </div>
            </>
          )}
        </div>

        <div className="about8_items">
          <TariffsBlock context={context} page={type} autoStartEnabled={true} />
        </div>
      </div>
    </div>
  );
};

export default TrialPopup;
