// TODO: (UNSTABLE) Rewrote this to TS with ChatGPT / Refactor
export const cookie = {
  get: function (cookieName: string): string | null {
    const results = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
    return results ? decodeURIComponent(results[2]) : null;
  },

  // TODO: Should receive object to avoid passing many undefined
  set: function (
    name: string,
    value: string,
    expYear?: number,
    expMonth?: number,
    expDay?: number,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Strict' | 'Lax' | 'None',
  ): void {
    let cookieString = `${name}=${encodeURIComponent(value)}`;

    if (expYear !== undefined && expMonth !== undefined && expDay !== undefined) {
      const expires = new Date(expYear, expMonth, expDay);
      cookieString += `; expires=${expires.toUTCString()}`;
    }

    if (path) cookieString += `; path=${encodeURIComponent(path)}`;
    if (domain) cookieString += `; domain=${encodeURIComponent(domain)}`;
    if (secure) cookieString += '; secure';
    if (sameSite) cookieString += `; SameSite=${sameSite}`;

    document.cookie = cookieString;
  },

  delete: function (cookieName: string, path?: string): void {
    const cookieDate = new Date();
    cookieDate.setTime(cookieDate.getTime() - 1);
    document.cookie = `${cookieName}=; expires=${cookieDate.toUTCString()}${
      path ? `; path=${encodeURIComponent(path)}` : ''
    }`;
  },

  deleteWithDomain: function (cookieName: string, path?: string, domain?: string): void {
    const cookieDate = new Date();
    cookieDate.setTime(cookieDate.getTime() - 1); // Устанавливаем прошедшую дату
    let cookieString = `${cookieName}=; expires=${cookieDate.toUTCString()};`;

    if (path) {
      cookieString += ` path=${encodeURIComponent(path)};`;
    }

    if (domain) {
      cookieString += ` domain=${encodeURIComponent(domain)};`;
    }

    document.cookie = cookieString;
  },
};
