import React, { useEffect, useRef } from 'react';
import { useForm, FieldError } from 'react-hook-form';
import IMask from 'imask';
import { InputField } from '@app/components/ui/input/ui/input';
import { useNavigate, useOutletContext } from 'react-router';
import { CommonOutletContext } from '@app/pages/common.outlet';
import { xhrHeaders } from '@lib/utils';
import { useToastContext } from '@app/hooks/use-toast-provider';
import { Button } from '@app/shared/ui/button';

import { setRedirectUrlForPayment } from '@app/store/slice/user.slice';
import { useAppDispatch } from '@app/hooks/use-store';

interface FormField {
  name: string;
}

interface DetailFormProps {
  fields: FormField[];
  submitUrl: string;
  onClose?: () => void;
  country?: string; // Страна для определения маски
}

export const DetailForm: React.FC<DetailFormProps> = ({ fields, submitUrl, country }) => {
  const { getToast } = useToastContext();
  const { context } = useOutletContext<CommonOutletContext>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const username = context?.user_ctx?.username;

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const phoneInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (username) {
      setValue('email', username, { shouldValidate: true });
    }
  }, [username, setValue]);

  useEffect(() => {
    if (phoneInputRef.current) {
      const maskOptions =
        country === 'India'
          ? {
              mask: '+91 00000 00000',
              lazy: false,
            }
          : {
              mask: '+{0} 0000000000',
              lazy: false,
            };

      const mask = IMask(phoneInputRef.current, maskOptions);

      mask.on('accept', () => {
        const formattedValue = mask.value;
        setValue('phone', formattedValue, { shouldValidate: true });
      });

      return () => {
        mask.destroy();
      };
    }
  }, [phoneInputRef, country]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    try {
      const cleanedData = {
        ...data,
        phone: data.phone.replace('+91 ', '').replace(/\s+/g, ''),
      };

      const formData = new FormData();
      Object.entries(cleanedData).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      const response = await fetch(submitUrl, {
        method: 'POST',
        headers: xhrHeaders(),
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const res = await response.json();
      window.sessionStorage.setItem('gotoProduct', JSON.stringify({ url: res?.result }));
      dispatch(setRedirectUrlForPayment(res?.result));
      navigate(`/redirect-payment`, { replace: true });
    } catch (error) {
      const errMessage = error instanceof Error ? error.message : String(error);
      getToast({
        systemMessage: {
          msg: errMessage,
          autoclose: 4000,
        },
      });
    }
  };

  const handleGenericChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let unmaskedValue;
    if (event.target.name === 'phone') {
      unmaskedValue = event.target.value.replace('+91 ', '');
    }
    setValue(event.target.name, unmaskedValue ?? event.target.value, { shouldValidate: true });
    trigger(event.target.name);
  };

  return (
    <>
      <form id="detailForm" className={`flex flex-col gap-6`} onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field) => (
          <div key={field.name} className="flex flex-col gap-1">
            <InputField
              label={field.name}
              name={field.name}
              type={field.name === 'email' ? 'email' : field.name === 'phone' ? 'text' : 'text'}
              register={register}
              rules={{
                required: field.name === 'phone' ? 'Phone number is required' : 'required',
                validate:
                  field.name === 'phone'
                    ? (value: string) => {
                        return (
                          value.replace(/\D/g, '').length >= 12 ||
                          'Phone number must be more 10 digits'
                        );
                      }
                    : true,
              }}
              onChange={handleGenericChange}
              ref={field.name === 'phone' ? phoneInputRef : undefined}
              extraClasses={
                errors[field.name]
                  ? 'outline outline-2 outline-solid outline-secondary_error_SI_400_error'
                  : ''
              }
            />
            {errors[field.name] && (
              <span className="text-secondary_error_SI_400_error text-sm leading-4 mb-[4px] self-start mt">
                {(errors[field.name] as FieldError)?.message || 'Error occurred'}
              </span>
            )}
          </div>
        ))}
      </form>
      <Button
        form="detailForm"
        type="submit"
        disabled={isSubmitting || !isValid}
        className="rounded-[8px] min-w-[296px] mt-10 w-full"
      >
        Proceed to payment
      </Button>
    </>
  );
};
