import { FC } from 'react';

import { NavLink, useLocation, useParams } from 'react-router-dom';

import { Container } from '@app/shared/ui/container';
import { BurgerButton } from '@app/shared/ui/burger-button';
import { CrossIcon, RightArrowAltIcon } from '@app/components/ui/icons';
import { route_path } from '@app/utils/route_path';
import { useAppDispatch, useAppSelector } from '@app/hooks/use-store';
import { isHeaderMenuOpen, toggleHeaderMenu } from '@app/widgets/header/model/header.slice';

import { useMediaQuery } from 'usehooks-ts';

import { useLoadPresentationQuery } from '@app/store/api/presentation.api';

import { cn } from '@app/app/lib';

import { screens } from '../../../../../../config/tailwind';

import {
  cvaArrowIcon,
  cvaContent,
  cvaNavigation,
  cvaNavigationItem,
  cvaRoot,
} from './stage-header-styles';

type StageHeaderProps = {
  isOpen: boolean;
  onToggle: () => void;
};

export const StageHeader: FC<StageHeaderProps> = ({ isOpen, onToggle }) => {
  const params = useParams();
  const { pathname } = useLocation();

  const { data: presentationData } = useLoadPresentationQuery(
    {
      projectId: String(params.projectId),
    },
    { skip: !params.projectId },
  );

  const isPptxSlides = Object.values(presentationData?.result?.slides_info ?? {}).every(
    (slide) => slide?.slide_type === 'pptx',
  );

  const isMenuOpen = useAppSelector(isHeaderMenuOpen);
  const dispatch = useAppDispatch();
  const handleToggleMenuOpen = () => dispatch(toggleHeaderMenu(!isMenuOpen));

  const isMobile = useMediaQuery(`(max-width: ${screens.screens.md})`);

  const isProject = params.projectId !== undefined;
  const isOptionInitialized = !!(presentationData?.result?.process?.option ?? []).find(
    (option) => Object.keys(option).length > 0,
  );

  return (
    /* TODO: Remove header-font */
    <div className={cn(cvaRoot(), 'header-font')}>
      <Container>
        <div className={cvaContent()}>
          <nav className={cvaNavigation()}>
            <NavLink to={route_path.create} className={cvaNavigationItem({ isActive: false })}>
              Choose mode
            </NavLink>

            {isPptxSlides && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                <NavLink
                  to={route_path.uploadDraft}
                  className={({ isActive }) => cvaNavigationItem({ isActive })}
                >
                  Upload draft
                </NavLink>
              </>
            )}

            {/* TODO: No route */}
            {/*<RightArrowAltIcon className={cvaArrowIcon()} />*/}
            {/*<NavLink to="" className={cvaNavigationItem({ isActive: false })}>*/}
            {/*  Input content*/}
            {/*</NavLink>*/}

            {/* TODO: No route */}
            {/*<RightArrowAltIcon className={cvaArrowIcon()} />*/}
            {/*<NavLink to="" className={cvaNavigationItem({ isActive: false })}>*/}
            {/*  Content preview*/}
            {/*</NavLink>*/}

            {isProject && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                <NavLink
                  to={route_path.setupStyle + String(params.projectId)}
                  className={({ isActive }) => cvaNavigationItem({ isActive })}
                >
                  Style design
                </NavLink>
              </>
            )}

            {isProject && isOptionInitialized && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                <NavLink
                  to={route_path.project + String(params.projectId)}
                  className={({ isActive }) => cvaNavigationItem({ isActive })}
                >
                  {isPptxSlides ? 'Finalize' : 'Create & Edit slides'}
                </NavLink>
              </>
            )}
          </nav>

          {isMobile ? (
            <BurgerButton
              isOpen={isMenuOpen}
              activeIcon={CrossIcon}
              onClick={handleToggleMenuOpen}
              className="pointer-events-auto"
            />
          ) : (
            <BurgerButton
              isOpen={isOpen}
              onClick={onToggle}
              activeIcon={CrossIcon}
              className={cn('pointer-events-auto', isOpen && 'opacity-0')}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
