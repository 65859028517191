import { cva } from 'class-variance-authority';

export const cvaMenuContentMobile = cva([
  'mobile-menu-styles-cvaMenuContentMobile',
  'overflow-y-auto',
  'fixed py-9 top-[4.5rem] inset-x-0 bottom-0 w-full ml-0',
  'flex flex-col items-center gap-9',
  'z-[100] bg-general_background_MIII_500_dark',
  'data-[state=open]:animate-in ease-in data-[state=closed]:animate-out duration-250',
  'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
]);

export const cvaActionsMenuMobile = cva([
  'mobile-menu-styles-cvaActionsMenuMobile',
  'flex flex-col items-center gap-9',
  'order-first',
]);

export const cvaNavMenuMobile = cva([
  'mobile-menu-styles-cvaNavMenuMobile',
  'flex items-center flex-col gap-9',
]);
