import { cva } from 'class-variance-authority';

export const cvaContainer = cva(['payments-dialog-styles-cvaContainer', 'px-10 pb-10 pt-8']);

export const cvaTitle = cva([
  'payments-dialog-styles-cvaTitle',
  'mb-6 text-general_text_MII_default text-font_desktop_display_display_small_32',
]);

export const cvaContent = cva(['payments-dialog-styles-cvaContent', 'flex flex-col']);

export const cvaContentWithGap = cva([
  'payments-dialog-styles-cvaContentWithGap',
  'flex flex-col gap-6',
]);

export const cvaContentRow = cva([
  'payments-dialog-styles-cvaContentRow',
  'flex justify-between items-center gap-x-6 gap-y-4',
  'xs-max:flex-col xs-max:items-start',
]);

export const cvaText = cva([
  'payments-dialog-styles-cvaText',
  'text-general_text_MII_default text-font_desktop_text_text_large',
]);

export const cvaTextDate = cva([
  'payments-dialog-styles-cvaTextDate',
  'text-general_text_MII_secondary_i',
]);

export const cvaButtons = cva(['payments-dialog-styles-cvaButtons', 'flex gap-6 xs-max:flex-col'], {
  variants: {
    withMargin: {
      true: 'mt-6',
      false: '',
    },
  },
  defaultVariants: {
    withMargin: false,
  },
});

export const cvaButton = cva([
  'payments-dialog-styles-cvaButton',
  'max-w-[212px] xs-max:max-w-full',
]);
