import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, useOutletContext } from 'react-router';

const WithoutHeaderLayout = () => {
  const context = useOutletContext();
  return (
    <div className="main">
      <Outlet context={context} />
    </div>
  );
};

WithoutHeaderLayout.prototype = {
  updateData: PropTypes.func,
  context: PropTypes.object,
};

export default WithoutHeaderLayout;
