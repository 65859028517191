import { Link } from 'react-router-dom';
import { FC } from 'react';
import { ProductInit } from '@app/types/product.type';
import { UserData } from '@app/types/user.type';

type FreeTariffBlockProps = {
  productData?: ProductInit;
  context: UserData | undefined;
};

const FreeTariffBlock: FC<FreeTariffBlockProps> = (props) => {
  const { productData, context } = props;
  const product_balance = context?.product_balance;

  if (!productData) return <></>;
  return (
    <div className="about8_item_wrap_free">
      <div
        className={
          'about8_item' +
          (product_balance && product_balance.product && product_balance.product?.is_trial
            ? ' current'
            : '')
        }
      >
        <div className="about8_item__title_tariff about8_item__title__line">
          <h3 className="about8_item__title">Free trial</h3>
        </div>
        <div className="tariff_subtitle">7-day access</div>
        <div className="about8_item__descr about8_item__descr__nofocus ">
          Great chance to try the magic of&nbsp;Wonderslide
        </div>
        <div className="about8_item__price">
          {productData?.products[0].price.currency_sign} {+String(0.0)}
        </div>
        <ul className="about8_item__features">
          <li>
            <strong>3 presentations to&nbsp;create and share</strong>
          </li>
          <li>
            <strong>3 slides to&nbsp;download </strong> for&nbsp;each presentation
          </li>
          <li>10 slides to upload for each presentation</li>
          <li>2 presentation styles available</li>
          <li>Limited access to features library</li>
        </ul>
        {product_balance && product_balance.is_active && product_balance.product?.is_trial && (
          <span className="about8_item__note">Your current plan</span>
        )}
        {product_balance && !product_balance.is_active && context?.user_ctx && (
          <span className="about8_item__note">Expired</span>
        )}
        {!context?.user_ctx && (
          <Link to="/create/" className="about8_item__btn btn-outlined">
            Get started
          </Link>
        )}
      </div>
    </div>
  );
};

export default FreeTariffBlock;
