import { cva } from 'class-variance-authority';

export const cvaRoot = cva(
  ['header-styles-cvaRoot', 'relative', 'transition-colors ease-in duration-250'],
  {
    variants: {
      isTransparent: {
        true: 'bg-general_background_MIII_500_dark/0',
        false: 'bg-general_background_MIII_500_dark',
      },
    },
    defaultVariants: {
      isTransparent: false,
    },
  },
);

export const cvaMainHeader = cva([
  'header-styles-cvaMainHeader',
  'flex items-center px-5 pt-6 pb-2 h-[2.5rem]',
]);

export const cvaContainer = cva(['header-styles-cvaContainer', 'w-full flex items-center']);

export const cvaLogoLink = cva(['header-styles-cvaLogoLink', 'shrink-0 pointer-events-auto']);

export const cvaLogo = cva([
  'header-styles-cvaLogo',
  'w-[200px] h-[24px] text-general_background_MIII_500',
]);

export const cvaHeaderMainContent = cva([
  'header-styles-cvaHeaderMainContent',
  'flex items-center justify-between flex-grow gap-8 lg-max:gap-4',
  'ml-10 md-max:hidden lg-max:ml-4',
]);

export const cvaHeaderNavMenu = cva([
  'header-styles-cvaHeaderNavMenu',
  'flex items-center gap-8 lg-max:gap-4',
]);

export const cvaMenuItem = cva(
  [
    'header-styles-cvaMenuItem',
    'relative flex items-center gap-1.5',
    'hover:text-general_MI_500_default',
    'transition-colors',
    'whitespace-nowrap',
    'before:content-[""] before:absolute before:w-1 before:h-1 before:-left-4 before:rounded before:pointer-events-none',
    'first:before:hidden lg-max:before:hidden',
  ],
  {
    variants: {
      isActive: {
        true: 'text-general_MI_500_default',
        false: '',
      },
      isDotHidden: {
        true: 'before:hidden',
        false: '',
      },
      isTransparent: {
        true: 'text-general_text_MII_default before:bg-general_text_MII_default',
        false: 'text-general_text_MII_secondary_i before:bg-general_text_MII_secondary_i',
      },
    },
    defaultVariants: {
      isTransparent: false,
      isDotHidden: false,
    },
  },
);

export const cvaActionsMenu = cva([
  'header-styles-cvaAuthMenu',
  'flex items-center gap-8 ml-auto lg-max:gap-4',
]);

export const cvaDaysLeft = cva(['headerStyles-cvaDaysLeft', 'whitespace-nowrap'], {
  variants: {
    isTransparent: {
      true: 'text-general_text_MII_default',
      false: 'text-general_text_MII_secondary_i',
    },
  },
  defaultVariants: {
    isTransparent: false,
  },
});
