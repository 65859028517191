import type { FC } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@app/app/lib';

import { cvaIcon } from './elements';

const cvaRoot = cva(
  ['spinner-cvaRoot', 'transition-opacity pointer-events-none text-general_text_MII_default'],
  {
    variants: {
      isVisible: {
        true: 'opacity-100 visible',
        false: 'opacity-0 invisible',
      },
    },
    defaultVariants: {
      isVisible: true,
    },
  },
);

const cvaCircle = cva([
  'spinner-cvaCircle',
  'fill-transparent stroke-current block origin-center animate-spinCircle',
]);

type SpinnerProps = VariantProps<typeof cvaRoot> &
  VariantProps<typeof cvaIcon> & { className?: string };

export const Spinner: FC<SpinnerProps> = (props) => {
  const { size, isVisible, className } = props;

  return (
    <span className={cn(cvaRoot({ isVisible }), className)}>
      <svg
        stroke="currentColor"
        width="1em"
        height="1em"
        className={cn([cvaIcon({ size }), 'animate-spin'])}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={cvaCircle()}
          strokeDasharray="283"
          strokeDashoffset="200"
          strokeLinecap="round"
          strokeWidth="8"
          cx="50"
          cy="50"
          r="45"
        />
      </svg>
    </span>
  );
};
