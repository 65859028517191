import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/use-store';
import { clearRedirectUrlForPayment } from '@app/store/slice/user.slice';
import { route_path } from '@app/utils/route_path';

export const RedirectPaymentPage = () => {
  const navigate = useNavigate();
  const redirectUrl = useAppSelector((state) => state.userState.redirectUrlForPayment);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!redirectUrl) {
      dispatch(clearRedirectUrlForPayment());
      navigate(route_path.pricing);
      return;
    }

    try {
      const decodedUrl = decodeURIComponent(redirectUrl);
      const normalizedUrl = decodedUrl.replace(/^(.*?)\/\1$/, '$1');

      if (normalizedUrl && normalizedUrl.startsWith('/')) {
        const absoluteUrl = new URL(normalizedUrl, window.location.origin).href;

        const timer = setTimeout(() => {
          // window.location.href = absoluteUrl;
          history.pushState(null, '', location.pathname);
          location.replace(absoluteUrl);
        }, 100);

        const timerClearStore = setTimeout(() => {
          dispatch(clearRedirectUrlForPayment());
        }, 5000);

        return () => {
          clearTimeout(timer);
          clearTimeout(timerClearStore);
        };
      } else {
        dispatch(clearRedirectUrlForPayment());
        navigate('/error');
      }
    } catch (error) {
      console.error('Invalid redirect URL:', error);
      dispatch(clearRedirectUrlForPayment());
      navigate('/error');
    }
  }, [redirectUrl, navigate, dispatch]);

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '20px',
        color: 'white',
        minHeight: '88dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '10px',
      }}
    >
      <p className='header-styles-cvaMenuItem relative flex items-center gap-1.5 hover:text-general_MI_500_default transition-colors whitespace-nowrap before:content-[""] before:absolute before:w-1 before:h-1 before:-left-4 before:rounded before:pointer-events-none first:before:hidden lg-max:before:hidden text-general_text_MII_secondary_i before:bg-general_text_MII_secondary_i'>
        You will be redirected to the payment page in 5 seconds.
      </p>
      <img src="/static/i/gif/pulse_bg.gif" alt="processing" className="w-12 h-12" />
    </div>
  );
};
