import t from '../../../../lib/lng';

export const navLinks = [
  { id: 1, to: '/features/', title: t.Features },
  { id: 2, to: '/pricing/', title: t.Pricing },
  { id: 3, to: '/about/', title: t.AboutUs },
];

export const navLinksV2 = [
  { id: 1, to: '/features/', title: t.Features },
  { id: 3, to: '/about/', title: t.AboutUs },
];
